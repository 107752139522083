import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_MenuList = _resolveComponent("MenuList")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "font-input" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, {
        class: _normalizeClass(["dropdown-box", { disabled: _ctx.disabled }]),
        style: _normalizeStyle({ minWidth: `${_ctx.minWidth}px` }),
        tabindex: "0",
        onClick: _ctx.toggleOpen,
        onKeydown: _ctx.keydown,
        "data-hover-menu-spawner": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.activeEntry?.value || ""), 1),
          _createVNode(_component_IconLabel, {
            class: "dropdown-arrow",
            icon: 'DropdownArrow'
          })
        ]),
        _: 1
      }, 8, ["class", "style", "onClick", "onKeydown"]),
      _createVNode(_component_MenuList, {
        ref: "menulist",
        activeEntry: _ctx.activeEntry,
        "onUpdate:activeEntry": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeEntry) = $event)),
        open: _ctx.open,
        "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
        entries: [_ctx.entries],
        minWidth: _ctx.isStyle ? 0 : _ctx.minWidth,
        virtualScrollingEntryHeight: _ctx.isStyle ? 0 : 20,
        scrollableY: true,
        onNaturalWidth: _cache[2] || (_cache[2] = (newNaturalWidth) => (_ctx.isStyle && (_ctx.minWidth = newNaturalWidth)))
      }, null, 8, ["activeEntry", "open", "entries", "minWidth", "virtualScrollingEntryHeight"])
    ]),
    _: 1
  }))
}