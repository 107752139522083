
import { defineComponent, type PropType } from "vue";

import { isWidgetColumn, isWidgetRow, type WidgetColumn, type WidgetRow } from "@/wasm-communication/messages";

import PivotAssist from "@/components/widgets/assists/PivotAssist.vue";
import IconButton from "@/components/widgets/buttons/IconButton.vue";
import PopoverButton from "@/components/widgets/buttons/PopoverButton.vue";
import TextButton from "@/components/widgets/buttons/TextButton.vue";
import CheckboxInput from "@/components/widgets/inputs/CheckboxInput.vue";
import ColorInput from "@/components/widgets/inputs/ColorInput.vue";
import DropdownInput from "@/components/widgets/inputs/DropdownInput.vue";
import FontInput from "@/components/widgets/inputs/FontInput.vue";
import NumberInput from "@/components/widgets/inputs/NumberInput.vue";
import OptionalInput from "@/components/widgets/inputs/OptionalInput.vue";
import RadioInput from "@/components/widgets/inputs/RadioInput.vue";
import SwatchPairInput from "@/components/widgets/inputs/SwatchPairInput.vue";
import TextAreaInput from "@/components/widgets/inputs/TextAreaInput.vue";
import TextInput from "@/components/widgets/inputs/TextInput.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import Separator from "@/components/widgets/labels/Separator.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";

export default defineComponent({
	inject: ["editor"],
	props: {
		widgetData: { type: Object as PropType<WidgetColumn | WidgetRow>, required: true },
		layoutTarget: { required: true },
	},
	data() {
		return {
			open: false,
		};
	},
	computed: {
		direction() {
			if (isWidgetColumn(this.widgetData)) return "column";
			if (isWidgetRow(this.widgetData)) return "row";
			return "ERROR";
		},
		widgets() {
			if (isWidgetColumn(this.widgetData)) return this.widgetData.columnWidgets;
			if (isWidgetRow(this.widgetData)) return this.widgetData.rowWidgets;
			return [];
		},
	},
	methods: {
		updateLayout(widgetId: bigint, value: unknown) {
			this.editor.instance.updateLayout(this.layoutTarget, widgetId, value);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		withoutValue(props: Record<string, any>): Record<string, unknown> {
			const { value: _, ...rest } = props;
			return rest;
		},
	},
	components: {
		CheckboxInput,
		ColorInput,
		DropdownInput,
		FontInput,
		IconButton,
		IconLabel,
		NumberInput,
		OptionalInput,
		PivotAssist,
		PopoverButton,
		RadioInput,
		Separator,
		SwatchPairInput,
		TextAreaInput,
		TextButton,
		TextInput,
		TextLabel,
	},
});
