
import { defineComponent, nextTick } from "vue";

import DialogModal from "@/components/floating-menus/DialogModal.vue";
import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import Panel from "@/components/window/workspace/Panel.vue";

const MIN_PANEL_SIZE = 100;

export default defineComponent({
	inject: ["workspace", "portfolio", "dialog", "editor"],
	computed: {
		activeDocumentIndex() {
			return this.portfolio.state.activeDocumentIndex;
		},
		nodeGraphVisible() {
			return this.workspace.state.nodeGraphVisible;
		},
	},
	methods: {
		resizePanel(event: PointerEvent) {
			const gutter = event.target as HTMLElement;
			const nextSibling = gutter.nextElementSibling as HTMLElement;
			const previousSibling = gutter.previousElementSibling as HTMLElement;

			// Are we resizing horizontally?
			const horizontal = gutter.classList.contains("layout-col");

			// Get the current size in px of the panels being resized
			const nextSiblingSize = horizontal ? nextSibling.getBoundingClientRect().width : nextSibling.getBoundingClientRect().height;
			const previousSiblingSize = horizontal ? previousSibling.getBoundingClientRect().width : previousSibling.getBoundingClientRect().height;

			// Prevent cursor flicker as mouse temporarily leaves the gutter
			gutter.setPointerCapture(event.pointerId);

			const mouseStart = horizontal ? event.clientX : event.clientY;

			function updatePosition(event: PointerEvent): void {
				const mouseCurrent = horizontal ? event.clientX : event.clientY;
				let mouseDelta = mouseStart - mouseCurrent;

				mouseDelta = Math.max(nextSiblingSize + mouseDelta, MIN_PANEL_SIZE) - nextSiblingSize;
				mouseDelta = previousSiblingSize - Math.max(previousSiblingSize - mouseDelta, MIN_PANEL_SIZE);

				nextSibling.style.flexGrow = (nextSiblingSize + mouseDelta).toString();
				previousSibling.style.flexGrow = (previousSiblingSize - mouseDelta).toString();

				window.dispatchEvent(new CustomEvent("resize"));
			}

			function cleanup(event: PointerEvent): void {
				gutter.releasePointerCapture(event.pointerId);

				document.removeEventListener("pointermove", updatePosition);
				document.removeEventListener("pointerleave", cleanup);
				document.removeEventListener("pointerup", cleanup);
			}

			document.addEventListener("pointermove", updatePosition);
			document.addEventListener("pointerleave", cleanup);
			document.addEventListener("pointerup", cleanup);
		},
	},
	watch: {
		async activeDocumentIndex(newIndex: number) {
			await nextTick();

			const documentsPanel = this.$refs.documentsPanel as typeof Panel;
			const newActiveTab = documentsPanel.$el.querySelectorAll("[data-tab-bar] [data-tab]")[newIndex];
			newActiveTab.scrollIntoView();
		},
	},
	components: {
		DialogModal,
		LayoutCol,
		LayoutRow,
		Panel,
	},
});
