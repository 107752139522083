
import { defineComponent, type PropType } from "vue";

import { type IconName } from "@/utility-functions/icons";

import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";

export default defineComponent({
	emits: ["update:checked"],
	props: {
		checked: { type: Boolean as PropType<boolean>, default: false },
		icon: { type: String as PropType<IconName>, default: "Checkmark" },
		tooltip: { type: String as PropType<string | undefined>, required: false },
	},
	data() {
		return {
			id: `${Math.random()}`.substring(2),
		};
	},
	methods: {
		isChecked() {
			return this.checked;
		},
	},
	components: {
		IconLabel,
		LayoutRow,
	},
});
