
import { defineComponent } from "vue";

import { platformIsMac } from "@/utility-functions/platform";
import { type HintData, type HintInfo, type KeysGroup, UpdateInputHints } from "@/wasm-communication/messages";

import LayoutRow from "@/components/layout/LayoutRow.vue";
import Separator from "@/components/widgets/labels/Separator.vue";
import UserInputLabel from "@/components/widgets/labels/UserInputLabel.vue";

export default defineComponent({
	inject: ["editor"],
	data() {
		return {
			hintData: [] as HintData,
		};
	},
	methods: {
		inputKeysForPlatform(hint: HintInfo): KeysGroup[] {
			if (platformIsMac() && hint.keyGroupsMac) return hint.keyGroupsMac;
			return hint.keyGroups;
		},
	},
	mounted() {
		this.editor.subscriptions.subscribeJsMessage(UpdateInputHints, (updateInputHints) => {
			this.hintData = updateInputHints.hintData;
		});
	},
	components: {
		LayoutRow,
		Separator,
		UserInputLabel,
	},
});
