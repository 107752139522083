
import { defineComponent, nextTick } from "vue";

import { textInputCleanup } from "@/utility-functions/keyboard-entry";
import {
	defaultWidgetLayout,
	type DisplayEditableTextbox,
	type MouseCursorIcon,
	type UpdateDocumentBarLayout,
	type UpdateDocumentModeLayout,
	type UpdateToolOptionsLayout,
	type UpdateToolShelfLayout,
	type UpdateWorkingColorsLayout,
	type XY,
} from "@/wasm-communication/messages";

import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import CanvasRuler from "@/components/widgets/metrics/CanvasRuler.vue";
import PersistentScrollbar from "@/components/widgets/metrics/PersistentScrollbar.vue";
import WidgetLayout from "@/components/widgets/WidgetLayout.vue";

export default defineComponent({
	inject: ["editor", "panels"],
	methods: {
		pasteFile(e: DragEvent) {
			const { dataTransfer } = e;
			if (!dataTransfer) return;
			e.preventDefault();

			Array.from(dataTransfer.items).forEach(async (item) => {
				const file = item.getAsFile();
				if (file?.type.startsWith("image")) {
					const buffer = await file.arrayBuffer();
					const u8Array = new Uint8Array(buffer);

					this.editor.instance.pasteImage(file.type, u8Array, e.clientX, e.clientY);
				}
			});
		},
		translateCanvasX(newValue: number) {
			const delta = newValue - this.scrollbarPos.x;
			this.scrollbarPos.x = newValue;
			this.editor.instance.translateCanvas(-delta * this.scrollbarMultiplier.x, 0);
		},
		translateCanvasY(newValue: number) {
			const delta = newValue - this.scrollbarPos.y;
			this.scrollbarPos.y = newValue;
			this.editor.instance.translateCanvas(0, -delta * this.scrollbarMultiplier.y);
		},
		pageX(delta: number) {
			const move = delta < 0 ? 1 : -1;
			this.editor.instance.translateCanvasByFraction(move, 0);
		},
		pageY(delta: number) {
			const move = delta < 0 ? 1 : -1;
			this.editor.instance.translateCanvasByFraction(0, move);
		},
		canvasPointerDown(e: PointerEvent) {
			const onEditbox = e.target instanceof HTMLDivElement && e.target.contentEditable;
			if (!onEditbox) {
				const canvas = this.$refs.canvas as HTMLElement;
				canvas.setPointerCapture(e.pointerId);
			}
		},
		// Update rendered SVGs
		async updateDocumentArtwork(svg: string) {
			this.artworkSvg = svg;

			await nextTick();

			if (this.textInput) {
				const canvas = this.$refs.canvas as HTMLElement;
				const foreignObject = canvas.getElementsByTagName("foreignObject")[0] as SVGForeignObjectElement;
				if (foreignObject.children.length > 0) return;

				const addedInput = foreignObject.appendChild(this.textInput);
				window.dispatchEvent(new CustomEvent("modifyinputfield", { detail: addedInput }));

				await nextTick();

				// Necessary to select contenteditable: https://stackoverflow.com/questions/6139107/programmatically-select-text-in-a-contenteditable-html-element/6150060#6150060

				const range = document.createRange();
				range.selectNodeContents(addedInput);

				const selection = window.getSelection();
				if (selection) {
					selection.removeAllRanges();
					selection.addRange(range);
				}

				addedInput.focus();
				addedInput.click();
			}
		},
		updateDocumentOverlays(svg: string) {
			this.overlaysSvg = svg;
		},
		updateDocumentArtboards(svg: string) {
			this.artboardSvg = svg;
		},
		// Update scrollbars and rulers
		updateDocumentScrollbars(position: XY, size: XY, multiplier: XY) {
			this.scrollbarPos = position;
			this.scrollbarSize = size;
			this.scrollbarMultiplier = multiplier;
		},
		updateDocumentRulers(origin: XY, spacing: number, interval: number) {
			this.rulerOrigin = origin;
			this.rulerSpacing = spacing;
			this.rulerInterval = interval;
		},
		// Update mouse cursor icon
		updateMouseCursor(cursor: MouseCursorIcon) {
			this.canvasCursor = cursor;
		},
		// Text entry
		triggerTextCommit() {
			if (!this.textInput) return;
			const textCleaned = textInputCleanup(this.textInput.innerText);
			this.editor.instance.onChangeText(textCleaned);
		},
		displayEditableTextbox(displayEditableTextbox: DisplayEditableTextbox) {
			this.textInput = document.createElement("DIV") as HTMLDivElement;

			if (displayEditableTextbox.text === "") this.textInput.textContent = "";
			else this.textInput.textContent = `${displayEditableTextbox.text}\n`;

			this.textInput.contentEditable = "true";
			this.textInput.style.width = displayEditableTextbox.lineWidth ? `${displayEditableTextbox.lineWidth}px` : "max-content";
			this.textInput.style.height = "auto";
			this.textInput.style.fontSize = `${displayEditableTextbox.fontSize}px`;
			this.textInput.style.color = displayEditableTextbox.color.toRgbaCSS();

			this.textInput.oninput = (): void => {
				if (!this.textInput) return;
				this.editor.instance.updateBounds(textInputCleanup(this.textInput.innerText));
			};
		},
		displayRemoveEditableTextbox() {
			this.textInput = undefined;
			window.dispatchEvent(new CustomEvent("modifyinputfield", { detail: undefined }));
		},
		// Update layouts
		updateDocumentModeLayout(updateDocumentModeLayout: UpdateDocumentModeLayout) {
			this.documentModeLayout = updateDocumentModeLayout;
		},
		updateToolOptionsLayout(updateToolOptionsLayout: UpdateToolOptionsLayout) {
			this.toolOptionsLayout = updateToolOptionsLayout;
		},
		updateDocumentBarLayout(updateDocumentBarLayout: UpdateDocumentBarLayout) {
			this.documentBarLayout = updateDocumentBarLayout;
		},
		updateToolShelfLayout(updateToolShelfLayout: UpdateToolShelfLayout) {
			this.toolShelfLayout = updateToolShelfLayout;
		},
		updateWorkingColorsLayout(updateWorkingColorsLayout: UpdateWorkingColorsLayout) {
			this.workingColorsLayout = updateWorkingColorsLayout;
		},
		// Resize elements to render the new viewport size
		viewportResize() {
			// Resize the canvas
			// Width and height are rounded up to the nearest even number because resizing is centered, and dividing an odd number by 2 for centering causes antialiasing
			const canvas = this.$refs.canvas as HTMLElement;
			const width = Math.ceil(parseFloat(getComputedStyle(canvas).width));
			const height = Math.ceil(parseFloat(getComputedStyle(canvas).height));
			this.canvasSvgWidth = `${width % 2 === 1 ? width + 1 : width}px`;
			this.canvasSvgHeight = `${height % 2 === 1 ? height + 1 : height}px`;

			// Resize the rulers
			const rulerHorizontal = this.$refs.rulerHorizontal as typeof CanvasRuler;
			const rulerVertical = this.$refs.rulerVertical as typeof CanvasRuler;
			rulerHorizontal?.resize();
			rulerVertical?.resize();
		},
	},
	mounted() {
		this.panels.registerPanel("Document", this);

		// Once this component is mounted, we want to resend the document bounds to the backend via the resize event handler which does that
		window.dispatchEvent(new Event("resize"));
	},
	data() {
		const scrollbarPos: XY = { x: 0.5, y: 0.5 };
		const scrollbarSize: XY = { x: 0.5, y: 0.5 };
		const scrollbarMultiplier: XY = { x: 0, y: 0 };

		const rulerOrigin: XY = { x: 0, y: 0 };

		return {
			// Interactive text editing
			textInput: undefined as undefined | HTMLDivElement,

			// CSS properties
			canvasSvgWidth: "100%" as string,
			canvasSvgHeight: "100%" as string,
			canvasCursor: "default" as MouseCursorIcon,

			// Scrollbars
			scrollbarPos,
			scrollbarSize,
			scrollbarMultiplier,

			// Rulers
			rulerOrigin,
			rulerSpacing: 100 as number,
			rulerInterval: 100 as number,

			// Rendered SVG viewport data
			artworkSvg: "" as string,
			artboardSvg: "" as string,
			overlaysSvg: "" as string,

			// Layouts
			documentModeLayout: defaultWidgetLayout(),
			toolOptionsLayout: defaultWidgetLayout(),
			documentBarLayout: defaultWidgetLayout(),
			toolShelfLayout: defaultWidgetLayout(),
			workingColorsLayout: defaultWidgetLayout(),
		};
	},
	components: {
		CanvasRuler,
		LayoutCol,
		LayoutRow,
		PersistentScrollbar,
		WidgetLayout,
	},
});
