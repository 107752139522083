
import { defineComponent } from "vue";

import { defaultWidgetLayout, UpdatePropertyPanelOptionsLayout, UpdatePropertyPanelSectionsLayout } from "@/wasm-communication/messages";

import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import WidgetLayout from "@/components/widgets/WidgetLayout.vue";

export default defineComponent({
	inject: ["editor", "dialog"],
	data() {
		return {
			propertiesOptionsLayout: defaultWidgetLayout(),
			propertiesSectionsLayout: defaultWidgetLayout(),
		};
	},
	mounted() {
		this.editor.subscriptions.subscribeJsMessage(UpdatePropertyPanelOptionsLayout, (updatePropertyPanelOptionsLayout) => {
			this.propertiesOptionsLayout = updatePropertyPanelOptionsLayout;
		});

		this.editor.subscriptions.subscribeJsMessage(UpdatePropertyPanelSectionsLayout, (updatePropertyPanelSectionsLayout) => {
			this.propertiesSectionsLayout = updatePropertyPanelSectionsLayout;
		});
	},
	components: {
		LayoutCol,
		LayoutRow,
		WidgetLayout,
	},
});
