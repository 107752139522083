
import { defineComponent } from "vue";

import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";

const WHEEL_RATE = 1 / 600;
const GRID_COLLAPSE_SPACING = 10;
const GRID_SIZE = 24;

export default defineComponent({
	data() {
		return {
			transform: { scale: 1, x: 0, y: 0 },
			panning: false,
			drawing: undefined as { port: HTMLElement; output: boolean; path: SVGElement } | undefined,
		};
	},
	computed: {
		gridSpacing(): number {
			const dense = this.transform.scale * GRID_SIZE;
			let sparse = dense;

			while (sparse > 0 && sparse < GRID_COLLAPSE_SPACING) {
				sparse *= 2;
			}

			return sparse;
		},
		dotRadius(): number {
			return 1 + Math.floor(this.transform.scale - 0.5 + 0.001) / 2;
		},
	},
	methods: {
		buildWirePathString(outputBounds: DOMRect, inputBounds: DOMRect, verticalOut: boolean, verticalIn: boolean): string {
			const containerBounds = (this.$refs.nodesContainer as HTMLElement).getBoundingClientRect();

			const outX = verticalOut ? outputBounds.x + outputBounds.width / 2 : outputBounds.x + outputBounds.width - 1;
			const outY = verticalOut ? outputBounds.y + 1 : outputBounds.y + outputBounds.height / 2;
			const outConnectorX = (outX - containerBounds.x) / this.transform.scale;
			const outConnectorY = (outY - containerBounds.y) / this.transform.scale;

			const inX = verticalIn ? inputBounds.x + inputBounds.width / 2 : inputBounds.x + 1;
			const inY = verticalIn ? inputBounds.y + inputBounds.height - 1 : inputBounds.y + inputBounds.height / 2;
			const inConnectorX = (inX - containerBounds.x) / this.transform.scale;
			const inConnectorY = (inY - containerBounds.y) / this.transform.scale;
			// debugger;
			const horizontalGap = Math.abs(outConnectorX - inConnectorX);
			const verticalGap = Math.abs(outConnectorY - inConnectorY);

			const curveLength = 200;
			const curveFalloffRate = curveLength * Math.PI * 2;

			const horizontalCurveAmount = -(2 ** ((-10 * horizontalGap) / curveFalloffRate)) + 1;
			const verticalCurveAmount = -(2 ** ((-10 * verticalGap) / curveFalloffRate)) + 1;
			const horizontalCurve = horizontalCurveAmount * curveLength;
			const verticalCurve = verticalCurveAmount * curveLength;

			return `M${outConnectorX},${outConnectorY} C${verticalOut ? outConnectorX : outConnectorX + horizontalCurve},${verticalOut ? outConnectorY - verticalCurve : outConnectorY} ${
				verticalIn ? inConnectorX : inConnectorX - horizontalCurve
			},${verticalIn ? inConnectorY + verticalCurve : inConnectorY} ${inConnectorX},${inConnectorY}`;
		},
		createWirePath(outputPort: HTMLElement, inputPort: HTMLElement, verticalOut: boolean, verticalIn: boolean): SVGPathElement {
			const pathString = this.buildWirePathString(outputPort.getBoundingClientRect(), inputPort.getBoundingClientRect(), verticalOut, verticalIn);
			const dataType = outputPort.dataset.datatype;

			const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
			path.setAttribute("d", pathString);
			path.setAttribute("style", `--data-color:  var(--color-data-${dataType}); --data-color-dim: var(--color-data-${dataType}-dim)`);
			(this.$refs.wiresContainer as HTMLElement).appendChild(path);

			return path;
		},
		scroll(e: WheelEvent) {
			const scroll = e.deltaY;
			let zoomFactor = 1 + Math.abs(scroll) * WHEEL_RATE;
			if (scroll > 0) zoomFactor = 1 / zoomFactor;

			const { x, y, width, height } = ((this.$refs.graph as typeof LayoutCol).$el as HTMLElement).getBoundingClientRect();

			this.transform.scale *= zoomFactor;

			const newViewportX = width / zoomFactor;
			const newViewportY = height / zoomFactor;

			const deltaSizeX = width - newViewportX;
			const deltaSizeY = height - newViewportY;

			const deltaX = deltaSizeX * ((e.x - x) / width);
			const deltaY = deltaSizeY * ((e.y - y) / height);

			this.transform.x -= (deltaX / this.transform.scale) * zoomFactor;
			this.transform.y -= (deltaY / this.transform.scale) * zoomFactor;
		},
		pointerDown(e: PointerEvent) {
			const port = (e.target as HTMLElement).closest(".port") as HTMLElement;

			if (port) {
				const output = port.classList.contains("output");
				const path = this.createWirePath(port, port, false, false);
				this.drawing = { port, output, path };
			} else {
				this.panning = true;
			}
			((this.$refs.graph as typeof LayoutCol).$el as HTMLElement).setPointerCapture(e.pointerId);
		},
		pointerMove(e: PointerEvent) {
			if (this.panning) {
				this.transform.x += e.movementX / this.transform.scale;
				this.transform.y += e.movementY / this.transform.scale;
			} else if (this.drawing) {
				const mouse = new DOMRect(e.x, e.y);
				const port = this.drawing.port.getBoundingClientRect();
				const output = this.drawing.output ? port : mouse;
				const input = this.drawing.output ? mouse : port;

				const pathString = this.buildWirePathString(output, input, false, false);
				this.drawing.path.setAttribute("d", pathString);
			}
		},
		pointerUp(e: PointerEvent) {
			((this.$refs.graph as typeof LayoutCol).$el as HTMLElement).releasePointerCapture(e.pointerId);
			this.panning = false;
			this.drawing = undefined;
		},
	},
	mounted() {
		const outputPort1 = document.querySelectorAll(".output.port")[4] as HTMLElement;
		const inputPort1 = document.querySelectorAll(".input.port")[1] as HTMLElement;
		this.createWirePath(outputPort1, inputPort1, true, true);

		const outputPort2 = document.querySelectorAll(".output.port")[6] as HTMLElement;
		const inputPort2 = document.querySelectorAll(".input.port")[3] as HTMLElement;
		this.createWirePath(outputPort2, inputPort2, true, false);
	},
	components: {
		IconLabel,
		LayoutCol,
		LayoutRow,
		TextLabel,
	},
});
