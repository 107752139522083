
import { defineComponent, type PropType } from "vue";

import { platformIsMac } from "@/utility-functions/platform";

import LayoutRow from "@/components/layout/LayoutRow.vue";

export default defineComponent({
	emits: ["update:value", "textFocused", "textChanged", "cancelTextChange"],
	props: {
		value: { type: String as PropType<string>, required: true },
		label: { type: String as PropType<string>, required: false },
		spellcheck: { type: Boolean as PropType<boolean>, default: false },
		disabled: { type: Boolean as PropType<boolean>, default: false },
		textarea: { type: Boolean as PropType<boolean>, default: false },
	},
	data() {
		return {
			id: `${Math.random()}`.substring(2),
			macKeyboardLayout: platformIsMac(),
		};
	},
	computed: {
		inputValue: {
			get() {
				return this.value;
			},
			set(value: string) {
				this.$emit("update:value", value);
			},
		},
	},
	components: { LayoutRow },
});
