
import { defineComponent, nextTick } from "vue";

import { platformIsMac } from "@/utility-functions/platform";
import { type LayerPanelEntry, defaultWidgetLayout, UpdateDocumentLayerDetails, UpdateDocumentLayerTreeStructure, UpdateLayerTreeOptionsLayout } from "@/wasm-communication/messages";

import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconButton from "@/components/widgets/buttons/IconButton.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import WidgetLayout from "@/components/widgets/WidgetLayout.vue";

type LayerListingInfo = { folderIndex: number; bottomLayer: boolean; editingName: boolean; entry: LayerPanelEntry };

const RANGE_TO_INSERT_WITHIN_BOTTOM_FOLDER_NOT_ROOT = 20;
const LAYER_INDENT = 16;
const INSERT_MARK_MARGIN_LEFT = 4 + 32 + LAYER_INDENT;
const INSERT_MARK_OFFSET = 2;

type DraggingData = { insertFolder: BigUint64Array; insertIndex: number; highlightFolder: boolean; markerHeight: number };

export default defineComponent({
	inject: ["editor"],
	data() {
		return {
			// Layer data
			layerCache: new Map() as Map<string, LayerPanelEntry>, // TODO: replace with BigUint64Array as index
			layers: [] as LayerListingInfo[],

			// Interactive dragging
			draggable: true,
			draggingData: undefined as undefined | DraggingData,

			// Layouts
			layerTreeOptionsLayout: defaultWidgetLayout(),
		};
	},
	methods: {
		layerIndent(layer: LayerPanelEntry): string {
			return `${layer.path.length * LAYER_INDENT}px`;
		},
		markIndent(path: BigUint64Array): string {
			return `${INSERT_MARK_MARGIN_LEFT + path.length * LAYER_INDENT}px`;
		},
		markTopOffset(height: number): string {
			return `${height}px`;
		},
		toggleLayerVisibility(path: BigUint64Array) {
			this.editor.instance.toggleLayerVisibility(path);
		},
		handleExpandArrowClick(path: BigUint64Array) {
			this.editor.instance.toggleLayerExpansion(path);
		},
		async onEditLayerName(listing: LayerListingInfo) {
			if (listing.editingName) return;

			this.draggable = false;

			listing.editingName = true;
			const tree: HTMLElement = (this.$refs.layerTreeList as typeof LayoutCol).$el;

			await nextTick();
			(tree.querySelector("[data-text-input]:not([disabled])") as HTMLInputElement).select();
		},
		onEditLayerNameChange(listing: LayerListingInfo, inputElement: EventTarget | undefined) {
			// Eliminate duplicate events
			if (!listing.editingName) return;

			this.draggable = true;

			const name = (inputElement as HTMLInputElement).value;
			listing.editingName = false;
			this.editor.instance.setLayerName(listing.entry.path, name);
		},
		async onEditLayerNameDeselect(listing: LayerListingInfo) {
			this.draggable = true;

			listing.editingName = false;

			await nextTick();
			window.getSelection()?.removeAllRanges();
		},
		async selectLayer(ctrl: boolean, cmd: boolean, shift: boolean, listing: LayerListingInfo, event: Event) {
			if (listing.editingName) return;

			const ctrlOrCmd = platformIsMac() ? cmd : ctrl;
			// Pressing the Ctrl key on a Mac, or the Cmd key on another platform, is a violation of the `.exact` qualifier so we filter it out here
			const opposite = platformIsMac() ? ctrl : cmd;

			if (!opposite) this.editor.instance.selectLayer(listing.entry.path, ctrlOrCmd, shift);

			// We always want to stop propagation so the click event doesn't pass through the layer and cause a deselection by clicking the layer panel background
			// This is also why we cover the remaining cases not considered by the `.exact` qualifier, in the last two bindings on the layer element, with a `stopPropagation()` call
			event.stopPropagation();
		},
		async deselectAllLayers() {
			this.editor.instance.deselectAllLayers();
		},
		calculateDragIndex(tree: HTMLElement, clientY: number): DraggingData {
			const treeChildren = tree.children;
			const treeOffset = tree.getBoundingClientRect().top;

			// Closest distance to the middle of the row along the Y axis
			let closest = Infinity;

			// Folder to insert into
			let insertFolder = new BigUint64Array();

			// Insert index
			let insertIndex = -1;

			// Whether you are inserting into a folder and should show the folder outline
			let highlightFolder = false;

			let markerHeight = 0;
			let previousHeight = undefined as undefined | number;

			Array.from(treeChildren).forEach((treeChild, index) => {
				const layerComponents = treeChild.getElementsByClassName("layer");
				if (layerComponents.length !== 1) return;
				const child = layerComponents[0];

				const indexAttribute = child.getAttribute("data-index");
				if (!indexAttribute) return;
				const { folderIndex, entry: layer } = this.layers[parseInt(indexAttribute, 10)];

				const rect = child.getBoundingClientRect();
				const position = rect.top + rect.height / 2;
				const distance = position - clientY;

				// Inserting above current row
				if (distance > 0 && distance < closest) {
					insertFolder = layer.path.slice(0, layer.path.length - 1);
					insertIndex = folderIndex;
					highlightFolder = false;
					closest = distance;
					markerHeight = previousHeight || treeOffset + INSERT_MARK_OFFSET;
				}
				// Inserting below current row
				else if (distance > -closest && distance > -RANGE_TO_INSERT_WITHIN_BOTTOM_FOLDER_NOT_ROOT && distance < 0) {
					insertFolder = layer.layerType === "Folder" ? layer.path : layer.path.slice(0, layer.path.length - 1);
					insertIndex = layer.layerType === "Folder" ? 0 : folderIndex + 1;
					highlightFolder = layer.layerType === "Folder";
					closest = -distance;
					markerHeight = index === treeChildren.length - 1 ? rect.bottom - INSERT_MARK_OFFSET : rect.bottom;
				}
				// Inserting with no nesting at the end of the panel
				else if (closest === Infinity) {
					if (layer.path.length === 1) insertIndex = folderIndex + 1;

					markerHeight = rect.bottom - INSERT_MARK_OFFSET;
				}
				previousHeight = rect.bottom;
			});

			markerHeight -= treeOffset;

			return { insertFolder, insertIndex, highlightFolder, markerHeight };
		},
		async dragStart(event: DragEvent, listing: LayerListingInfo) {
			const layer = listing.entry;
			if (!layer.layerMetadata.selected) this.selectLayer(event.ctrlKey, event.metaKey, event.shiftKey, listing, event);

			// Set style of cursor for drag
			if (event.dataTransfer) {
				event.dataTransfer.dropEffect = "move";
				event.dataTransfer.effectAllowed = "move";
			}
			const tree = (this.$refs.layerTreeList as typeof LayoutCol).$el;

			this.draggingData = this.calculateDragIndex(tree, event.clientY);
		},
		updateInsertLine(event: DragEvent) {
			// Stop the drag from being shown as cancelled
			event.preventDefault();

			const tree: HTMLElement = (this.$refs.layerTreeList as typeof LayoutCol).$el;
			this.draggingData = this.calculateDragIndex(tree, event.clientY);
		},
		async drop() {
			if (this.draggingData) {
				const { insertFolder, insertIndex } = this.draggingData;

				this.editor.instance.moveLayerInTree(insertFolder, insertIndex);

				this.draggingData = undefined;
			}
		},
		rebuildLayerTree(updateDocumentLayerTreeStructure: UpdateDocumentLayerTreeStructure) {
			const layerWithNameBeingEdited = this.layers.find((layer: LayerListingInfo) => layer.editingName);
			const layerPathWithNameBeingEdited = layerWithNameBeingEdited?.entry.path;
			const layerIdWithNameBeingEdited = layerPathWithNameBeingEdited?.slice(-1)[0];
			const path = [] as bigint[];
			this.layers = [] as LayerListingInfo[];

			const recurse = (folder: UpdateDocumentLayerTreeStructure, layers: LayerListingInfo[], cache: Map<string, LayerPanelEntry>): void => {
				folder.children.forEach((item, index) => {
					// TODO: fix toString
					const layerId = BigInt(item.layerId.toString());
					path.push(layerId);

					const mapping = cache.get(path.toString());
					if (mapping) {
						layers.push({
							folderIndex: index,
							bottomLayer: index === folder.children.length - 1,
							entry: mapping,
							editingName: layerIdWithNameBeingEdited === layerId,
						});
					}

					// Call self recursively if there are any children
					if (item.children.length >= 1) recurse(item, layers, cache);

					path.pop();
				});
			};

			recurse(updateDocumentLayerTreeStructure, this.layers, this.layerCache);
		},
	},
	mounted() {
		this.editor.subscriptions.subscribeJsMessage(UpdateDocumentLayerTreeStructure, (updateDocumentLayerTreeStructure) => {
			this.rebuildLayerTree(updateDocumentLayerTreeStructure);
		});

		this.editor.subscriptions.subscribeJsMessage(UpdateLayerTreeOptionsLayout, (updateLayerTreeOptionsLayout) => {
			this.layerTreeOptionsLayout = updateLayerTreeOptionsLayout;
		});

		this.editor.subscriptions.subscribeJsMessage(UpdateDocumentLayerDetails, (updateDocumentLayerDetails) => {
			const targetPath = updateDocumentLayerDetails.data.path;
			const targetLayer = updateDocumentLayerDetails.data;

			const layer = this.layerCache.get(targetPath.toString());
			if (layer) {
				Object.assign(layer, targetLayer);
			} else {
				this.layerCache.set(targetPath.toString(), targetLayer);
			}
		});
	},
	components: {
		IconButton,
		IconLabel,
		LayoutCol,
		LayoutRow,
		WidgetLayout,
	},
});
