
import { defineComponent } from "vue";

import FloatingMenu from "@/components/floating-menus/FloatingMenu.vue";
import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import TextButton from "@/components/widgets/buttons/TextButton.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import WidgetLayout from "@/components/widgets/WidgetLayout.vue";

export default defineComponent({
	inject: ["dialog"],
	methods: {
		dismiss() {
			this.dialog.dismissDialog();
		},
	},
	mounted() {
		// Focus the first button in the popup
		const element = this.$el as Element | undefined;
		const emphasizedOrFirstButton = (element?.querySelector("[data-emphasized]") || element?.querySelector("[data-text-button]") || undefined) as HTMLButtonElement | undefined;
		emphasizedOrFirstButton?.focus();
	},
	components: {
		FloatingMenu,
		IconLabel,
		LayoutCol,
		LayoutRow,
		TextButton,
		WidgetLayout,
	},
});
