import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "widget-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.layout, (layoutRow, index) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.LayoutGroupType(layoutRow)), {
        widgetData: layoutRow,
        layoutTarget: _ctx.layout.layoutTarget,
        key: index
      }, null, 8, ["widgetData", "layoutTarget"]))
    }), 128))
  ]))
}