import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-scrollable-x", "data-scrollable-y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout-col", { 'scrollable-x': _ctx.scrollableX, 'scrollable-y': _ctx.scrollableY }]),
    "data-scrollable-x": _ctx.scrollableX || undefined,
    "data-scrollable-y": _ctx.scrollableY || undefined
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}