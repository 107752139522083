
import { defineComponent, type PropType } from "vue";

import { type RadioEntries, type RadioEntryData } from "@/wasm-communication/messages";

import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";

export default defineComponent({
	emits: ["update:selectedIndex"],
	props: {
		entries: { type: Array as PropType<RadioEntries>, required: true },
		selectedIndex: { type: Number as PropType<number>, required: true },
	},
	methods: {
		handleEntryClick(radioEntryData: RadioEntryData) {
			const index = this.entries.indexOf(radioEntryData);
			this.$emit("update:selectedIndex", index);

			radioEntryData.action?.();
		},
	},
	components: {
		IconLabel,
		LayoutRow,
		TextLabel,
	},
});
