import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "menu-bar-input",
  "data-menu-bar-input": ""
}
const _hoisted_2 = ["onClick", "onBlur", "onKeydown"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_MenuList = _resolveComponent("MenuList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (entry, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "entry-container",
        key: index
      }, [
        _createElementVNode("div", {
          onClick: (e) => _ctx.onClick(entry, e.target || undefined),
          onBlur: (e) => _ctx.blur(entry, e.target || undefined),
          onKeydown: (e) => entry.ref?.keydown(e, false),
          class: _normalizeClass(["entry", { open: entry.ref?.isOpen }]),
          tabindex: "0",
          "data-hover-menu-spawner": ""
        }, [
          (entry.icon)
            ? (_openBlock(), _createBlock(_component_IconLabel, {
                key: 0,
                icon: entry.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (entry.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(entry.label), 1))
            : _createCommentVNode("", true)
        ], 42, _hoisted_2),
        (entry.children && entry.children.length > 0)
          ? (_openBlock(), _createBlock(_component_MenuList, {
              key: 0,
              open: entry.ref?.open || false,
              entries: entry.children || [],
              direction: 'Bottom',
              minWidth: 240,
              drawIcon: true,
              ref_for: true,
              ref: (ref) => ref && (entry.ref = ref)
            }, null, 8, ["open", "entries"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}