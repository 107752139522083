
import { defineComponent, type PropType } from "vue";

import { type PivotPosition } from "@/wasm-communication/messages";

export default defineComponent({
	emits: ["update:position"],
	props: {
		position: { type: String as PropType<string>, required: true },
	},
	methods: {
		setPosition(newPosition: PivotPosition) {
			this.$emit("update:position", newPosition);
		},
	},
});
