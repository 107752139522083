import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, {
    class: _normalizeClass(['icon-label', _ctx.iconSizeClass, _ctx.iconStyleClass])
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon)))
    ]),
    _: 1
  }, 8, ["class"]))
}