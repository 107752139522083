import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_TextLabel = _resolveComponent("TextLabel")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "widget-section" }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "header",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => (_ctx.expanded = !_ctx.expanded), ["stop"]))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["expand-arrow", { expanded: _ctx.expanded }])
        }, null, 2),
        _createVNode(_component_Separator, { type: 'Related' }),
        _createVNode(_component_TextLabel, { bold: true }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.widgetData.name), 1)
          ]),
          _: 1
        })
      ]),
      (_ctx.expanded)
        ? (_openBlock(), _createBlock(_component_LayoutCol, {
            key: 0,
            class: "body"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgetData.layout, (layoutRow, index) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layoutGroupType(layoutRow)), {
                  widgetData: layoutRow,
                  layoutTarget: _ctx.layoutTarget,
                  key: index
                }, null, 8, ["widgetData", "layoutTarget"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}