import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldInput = _resolveComponent("FieldInput")!

  return (_openBlock(), _createBlock(_component_FieldInput, {
    class: "number-input",
    value: _ctx.text,
    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.text) = $event)),
    label: _ctx.label,
    spellcheck: false,
    disabled: _ctx.disabled,
    onTextFocused: _cache[3] || (_cache[3] = () => _ctx.onTextFocused()),
    onTextChanged: _cache[4] || (_cache[4] = () => _ctx.onTextChanged()),
    onCancelTextChange: _cache[5] || (_cache[5] = () => _ctx.onCancelTextChange()),
    ref: "fieldInput"
  }, {
    default: _withCtx(() => [
      (_ctx.value !== undefined)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "arrow left",
            onClick: _cache[0] || (_cache[0] = () => _ctx.onIncrement('Decrease'))
          }))
        : _createCommentVNode("", true),
      (_ctx.value !== undefined)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "arrow right",
            onClick: _cache[1] || (_cache[1] = () => _ctx.onIncrement('Increase'))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value", "label", "disabled"]))
}