import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["value", "placeholder", "disabled", "onBlur", "onKeydown", "onChange"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetLayout = _resolveComponent("WidgetLayout")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "layer-tree" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, {
        class: "options-bar",
        scrollableX: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_WidgetLayout, { layout: _ctx.layerTreeOptionsLayout }, null, 8, ["layout"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, {
        class: "layer-tree-rows",
        scrollableY: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutCol, {
            class: "list",
            ref: "layerTreeList",
            onClick: _cache[2] || (_cache[2] = () => _ctx.deselectAllLayers()),
            onDragover: _cache[3] || (_cache[3] = (e) => _ctx.draggable && _ctx.updateInsertLine(e)),
            onDragend: _cache[4] || (_cache[4] = () => _ctx.draggable && _ctx.drop())
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layers, (listing, index) => {
                return (_openBlock(), _createBlock(_component_LayoutRow, {
                  class: _normalizeClass(["layer-row", { 'insert-folder': _ctx.draggingData?.highlightFolder && _ctx.draggingData?.insertFolder === listing.entry.path }]),
                  key: String(listing.entry.path.slice(-1))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LayoutRow, { class: "visibility" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_IconButton, {
                          action: (e) => (_ctx.toggleLayerVisibility(listing.entry.path), e?.stopPropagation()),
                          size: 24,
                          icon: listing.entry.visible ? 'EyeVisible' : 'EyeHidden',
                          title: listing.entry.visible ? 'Visible' : 'Hidden'
                        }, null, 8, ["action", "icon", "title"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", {
                      class: "indent",
                      style: _normalizeStyle({ marginLeft: _ctx.layerIndent(listing.entry) })
                    }, null, 4),
                    (listing.entry.layerType === 'Folder')
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: _normalizeClass(["expand-arrow", { expanded: listing.entry.layerMetadata.expanded }]),
                          onClick: _withModifiers(($event: any) => (_ctx.handleExpandArrowClick(listing.entry.path)), ["stop"])
                        }, null, 10, _hoisted_1))
                      : _createCommentVNode("", true),
                    _createVNode(_component_LayoutRow, {
                      class: _normalizeClass(["layer", { selected: listing.entry.layerMetadata.selected }]),
                      "data-index": index,
                      title: listing.entry.tooltip,
                      draggable: _ctx.draggable,
                      onDragstart: (e) => _ctx.draggable && _ctx.dragStart(e, listing),
                      onClick: [
                        _withModifiers((e) => _ctx.selectLayer(false, false, false, listing, e), ["exact"]),
                        _withModifiers((e) => _ctx.selectLayer(false, false, true, listing, e), ["shift","exact"]),
                        _withModifiers((e) => _ctx.selectLayer(true, false, false, listing, e), ["ctrl","exact"]),
                        _withModifiers((e) => _ctx.selectLayer(true, false, true, listing, e), ["ctrl","shift","exact"]),
                        _withModifiers((e) => _ctx.selectLayer(false, true, false, listing, e), ["meta","exact"]),
                        _withModifiers((e) => _ctx.selectLayer(false, true, true, listing, e), ["meta","shift","exact"]),
                        _cache[0] || (_cache[0] = _withModifiers((e) => e.stopPropagation(), ["ctrl","meta"])),
                        _cache[1] || (_cache[1] = _withModifiers((e) => e.stopPropagation(), ["alt"]))
                      ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_LayoutRow, { class: "layer-type-icon" }, {
                          default: _withCtx(() => [
                            (listing.entry.layerType === 'Folder')
                              ? (_openBlock(), _createBlock(_component_IconLabel, {
                                  key: 0,
                                  icon: 'NodeFolder',
                                  iconStyle: 'Node',
                                  title: "Folder"
                                }))
                              : (listing.entry.layerType === 'Image')
                                ? (_openBlock(), _createBlock(_component_IconLabel, {
                                    key: 1,
                                    icon: 'NodeImage',
                                    iconStyle: 'Node',
                                    title: "Image"
                                  }))
                                : (listing.entry.layerType === 'Shape')
                                  ? (_openBlock(), _createBlock(_component_IconLabel, {
                                      key: 2,
                                      icon: 'NodeShape',
                                      iconStyle: 'Node',
                                      title: "Shape"
                                    }))
                                  : (listing.entry.layerType === 'Text')
                                    ? (_openBlock(), _createBlock(_component_IconLabel, {
                                        key: 3,
                                        icon: 'NodeText',
                                        iconStyle: 'Node',
                                        title: "Path"
                                      }))
                                    : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_LayoutRow, {
                          class: "layer-name",
                          onDblclick: () => _ctx.onEditLayerName(listing)
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("input", {
                              "data-text-input": "",
                              type: "text",
                              value: listing.entry.name,
                              placeholder: listing.entry.layerType,
                              disabled: !listing.editingName,
                              onBlur: () => _ctx.onEditLayerNameDeselect(listing),
                              onKeydown: [
                                _withKeys(($event: any) => (_ctx.onEditLayerNameDeselect(listing)), ["esc"]),
                                _withKeys((e) => _ctx.onEditLayerNameChange(listing, e.target || undefined), ["enter"])
                              ],
                              onChange: (e) => _ctx.onEditLayerNameChange(listing, e.target || undefined)
                            }, null, 40, _hoisted_2)
                          ]),
                          _: 2
                        }, 1032, ["onDblclick"]),
                        _createElementVNode("div", {
                          class: "thumbnail",
                          innerHTML: listing.entry.thumbnail
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1032, ["class", "data-index", "title", "draggable", "onDragstart", "onClick"])
                  ]),
                  _: 2
                }, 1032, ["class"]))
              }), 128))
            ]),
            _: 1
          }, 512),
          (_ctx.draggingData && !_ctx.draggingData.highlightFolder)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "insert-mark",
                style: _normalizeStyle({ left: _ctx.markIndent(_ctx.draggingData.insertFolder), top: _ctx.markTopOffset(_ctx.draggingData.markerHeight) })
              }, null, 4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}