import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetLayout = _resolveComponent("WidgetLayout")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "properties" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, { class: "options-bar" }, {
        default: _withCtx(() => [
          _createVNode(_component_WidgetLayout, { layout: _ctx.propertiesOptionsLayout }, null, 8, ["layout"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, {
        class: "sections",
        scrollableY: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_WidgetLayout, { layout: _ctx.propertiesSectionsLayout }, null, 8, ["layout"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}