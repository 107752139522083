
import { defineComponent, type PropType } from "vue";

import { type RGBA } from "@/wasm-communication/messages";

import ColorPicker from "@/components/floating-menus/ColorPicker.vue";
import FloatingMenu from "@/components/floating-menus/FloatingMenu.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import OptionalInput from "@/components/widgets/inputs/OptionalInput.vue";
import TextInput from "@/components/widgets/inputs/TextInput.vue";
import Separator from "@/components/widgets/labels/Separator.vue";

export default defineComponent({
	emits: ["update:value", "update:open"],
	props: {
		value: { type: String as PropType<string | undefined>, required: false },
		label: { type: String as PropType<string>, required: false },
		noTransparency: { type: Boolean as PropType<boolean>, default: false },
		disabled: { type: Boolean as PropType<boolean>, default: false },
		tooltip: { type: String as PropType<string | undefined>, required: false },

		// Bound through `v-model`
		// TODO: See if this should be made to follow the pattern of DropdownInput.vue so this could be removed
		open: { type: Boolean as PropType<boolean>, required: true },
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		color() {
			if (!this.value) return { r: 0, g: 0, b: 0, a: 1 };

			const r = parseInt(this.value.slice(0, 2), 16);
			const g = parseInt(this.value.slice(2, 4), 16);
			const b = parseInt(this.value.slice(4, 6), 16);
			const a = parseInt(this.value.slice(6, 8), 16);
			return { r, g, b, a: a / 255 };
		},
		displayValue() {
			if (!this.value) return "";

			const value = this.value.toLowerCase();
			const shortenedIfOpaque = value.slice(-2) === "ff" ? value.slice(0, 6) : value;
			return `#${shortenedIfOpaque}`;
		},
	},
	watch: {
		// Called only when `open` is changed from outside this component (with v-model)
		open(newOpen: boolean) {
			this.isOpen = newOpen;
		},
		isOpen(newIsOpen: boolean) {
			this.$emit("update:open", newIsOpen);
		},
	},
	methods: {
		colorPickerUpdated(color: RGBA) {
			const twoDigitHex = (value: number): string => value.toString(16).padStart(2, "0");
			const alphaU8Scale = Math.floor(color.a * 255);
			const newValue = `${twoDigitHex(color.r)}${twoDigitHex(color.g)}${twoDigitHex(color.b)}${twoDigitHex(alphaU8Scale)}`;
			this.$emit("update:value", newValue);
		},
		textInputUpdated(newValue: string) {
			const sanitizedMatch = newValue.match(/^\s*#?([0-9a-fA-F]{8}|[0-9a-fA-F]{6}|[0-9a-fA-F]{3})\s*$/);
			if (!sanitizedMatch) return;

			let sanitized;
			const match = sanitizedMatch[1];
			if (match.length === 3) {
				sanitized = match
					.split("")
					.map((byte) => `${byte}${byte}`)
					.concat("ff")
					.join("");
			} else if (match.length === 6) {
				sanitized = `${match}ff`;
			} else if (match.length === 8) {
				sanitized = match;
			} else {
				return;
			}

			this.$emit("update:value", sanitized);
		},
		updateEnabled(value: boolean) {
			if (value) this.$emit("update:value", "000000");
			else this.$emit("update:value", undefined);
		},
	},
	components: {
		ColorPicker,
		FloatingMenu,
		LayoutRow,
		OptionalInput,
		Separator,
		TextInput,
	},
});
