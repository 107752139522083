import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "popover-button" }, {
    default: _withCtx(() => [
      _createVNode(_component_IconButton, {
        action: () => _ctx.onClick(),
        icon: _ctx.icon,
        size: 16,
        "data-hover-menu-spawner": ""
      }, null, 8, ["action", "icon"]),
      _createVNode(_component_FloatingMenu, {
        open: _ctx.open,
        "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
        type: 'Popover',
        direction: 'Bottom'
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["open"])
    ]),
    _: 3
  }))
}