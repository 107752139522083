
import { defineComponent, type PropType } from "vue";

import LayoutRow from "@/components/layout/LayoutRow.vue";

export default defineComponent({
	props: {
		text: { type: String as PropType<string>, required: true },
	},
	components: { LayoutRow },
});
