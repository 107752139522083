import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!
  const _component_DialogModal = _resolveComponent("DialogModal")!

  return (_openBlock(), _createBlock(_component_LayoutRow, {
    class: "workspace",
    "data-workspace": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, { class: "workspace-grid-subdivision" }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutCol, { class: "workspace-grid-subdivision" }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutRow, { class: "workspace-grid-subdivision" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Panel, {
                    panelType: _ctx.portfolio.state.documents.length > 0 ? 'Document' : undefined,
                    tabCloseButtons: true,
                    tabMinWidths: true,
                    tabLabels: _ctx.portfolio.state.documents.map((doc) => doc.displayName),
                    clickAction: (tabIndex) => _ctx.editor.instance.selectDocument(_ctx.portfolio.state.documents[tabIndex].id),
                    closeAction: (tabIndex) => _ctx.editor.instance.closeDocumentWithConfirmation(_ctx.portfolio.state.documents[tabIndex].id),
                    tabActiveIndex: _ctx.portfolio.state.activeDocumentIndex,
                    ref: "documentsPanel"
                  }, null, 8, ["panelType", "tabLabels", "clickAction", "closeAction", "tabActiveIndex"])
                ]),
                _: 1
              }),
              (_ctx.nodeGraphVisible)
                ? (_openBlock(), _createBlock(_component_LayoutRow, {
                    key: 0,
                    class: "workspace-grid-resize-gutter",
                    onPointerdown: _cache[0] || (_cache[0] = (e) => _ctx.resizePanel(e))
                  }))
                : _createCommentVNode("", true),
              (_ctx.nodeGraphVisible)
                ? (_openBlock(), _createBlock(_component_LayoutRow, {
                    key: 1,
                    class: "workspace-grid-subdivision"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Panel, {
                        panelType: 'NodeGraph',
                        tabLabels: ['Node Graph'],
                        tabActiveIndex: 0
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutCol, {
            class: "workspace-grid-resize-gutter",
            onPointerdown: _cache[1] || (_cache[1] = (e) => _ctx.resizePanel(e))
          }),
          _createVNode(_component_LayoutCol, {
            class: "workspace-grid-subdivision",
            style: {"flex-grow":"0.17"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutRow, {
                class: "workspace-grid-subdivision",
                style: {"flex-grow":"402"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Panel, {
                    panelType: 'Properties',
                    tabLabels: ['Properties'],
                    tabActiveIndex: 0
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_LayoutRow, {
                class: "workspace-grid-resize-gutter",
                onPointerdown: _cache[2] || (_cache[2] = (e) => _ctx.resizePanel(e))
              }),
              _createVNode(_component_LayoutRow, {
                class: "workspace-grid-subdivision",
                style: {"flex-grow":"590"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Panel, {
                    panelType: 'LayerTree',
                    tabLabels: ['Layer Tree'],
                    tabActiveIndex: 0
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.dialog.state.visible)
        ? (_openBlock(), _createBlock(_component_DialogModal, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}