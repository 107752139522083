
import { defineComponent, type PropType, toRaw } from "vue";

import { type MenuListEntry } from "@/wasm-communication/messages";

import MenuList from "@/components/floating-menus/MenuList.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";

const DASH_ENTRY = { label: "-" };

export default defineComponent({
	emits: ["update:selectedIndex"],
	props: {
		entries: { type: Array as PropType<MenuListEntry[][]>, required: true },
		selectedIndex: { type: Number as PropType<number>, required: false }, // When not provided, a dash is displayed
		drawIcon: { type: Boolean as PropType<boolean>, default: false },
		interactive: { type: Boolean as PropType<boolean>, default: true },
		disabled: { type: Boolean as PropType<boolean>, default: false },
	},
	data() {
		return {
			activeEntry: this.makeActiveEntry(this.selectedIndex),
			activeEntrySkipWatcher: false,
			open: false,
			minWidth: 0,
		};
	},
	watch: {
		// Called only when `selectedIndex` is changed from outside this component (with v-model)
		selectedIndex() {
			this.activeEntrySkipWatcher = true;
			this.activeEntry = this.makeActiveEntry();
		},
		// Called when `activeEntry` is changed by the `v-model` on this component's MenuList component, or by the `selectedIndex()` watcher above (but we want to skip that case)
		activeEntry(newActiveEntry: MenuListEntry) {
			if (this.activeEntrySkipWatcher) {
				this.activeEntrySkipWatcher = false;
				return;
			}

			// `toRaw()` pulls it out of the Vue proxy
			if (toRaw(newActiveEntry) === DASH_ENTRY) return;

			this.$emit("update:selectedIndex", this.entries.flat().indexOf(newActiveEntry));
		},
	},
	methods: {
		makeActiveEntry(): MenuListEntry {
			const entries = this.entries.flat();

			if (this.selectedIndex !== undefined && this.selectedIndex >= 0 && this.selectedIndex < entries.length) {
				return entries[this.selectedIndex];
			}
			return DASH_ENTRY;
		},
		keydown(e: KeyboardEvent) {
			(this.$refs.menuList as typeof MenuList).keydown(e, false);
		},
		blur(e: FocusEvent) {
			if ((e.target as HTMLElement).closest("[data-dropdown-input]") !== this.$el) this.open = false;
		},
	},
	components: {
		IconLabel,
		LayoutRow,
		MenuList,
	},
});
