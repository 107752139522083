import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["persistent-scrollbar", _ctx.direction.toLowerCase()])
  }, [
    _createElementVNode("button", {
      class: "arrow decrease",
      onPointerdown: _cache[0] || (_cache[0] = () => _ctx.changePosition(-50))
    }, null, 32),
    _createElementVNode("div", {
      class: "scroll-track",
      ref: "scrollTrack",
      onPointerdown: _cache[2] || (_cache[2] = (e) => _ctx.grabArea(e))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["scroll-thumb", { dragging: _ctx.dragging }]),
        onPointerdown: _cache[1] || (_cache[1] = (e) => _ctx.grabHandle(e)),
        ref: "handle",
        style: _normalizeStyle([_ctx.thumbStart, _ctx.thumbEnd, _ctx.sides])
      }, null, 38)
    ], 544),
    _createElementVNode("button", {
      class: "arrow increase",
      onClick: _cache[3] || (_cache[3] = () => _ctx.changePosition(50))
    })
  ], 2))
}