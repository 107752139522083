import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WidgetLayout = _resolveComponent("WidgetLayout")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!
  const _component_CanvasRuler = _resolveComponent("CanvasRuler")!
  const _component_PersistentScrollbar = _resolveComponent("PersistentScrollbar")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "document" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, {
        class: "options-bar",
        scrollableX: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_WidgetLayout, { layout: _ctx.documentModeLayout }, null, 8, ["layout"]),
          _createVNode(_component_WidgetLayout, { layout: _ctx.toolOptionsLayout }, null, 8, ["layout"]),
          _createVNode(_component_LayoutRow, { class: "spacer" }),
          _createVNode(_component_WidgetLayout, { layout: _ctx.documentBarLayout }, null, 8, ["layout"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, { class: "shelf-and-viewport" }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutCol, { class: "shelf" }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutCol, {
                class: "tools",
                scrollableY: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_WidgetLayout, { layout: _ctx.toolShelfLayout }, null, 8, ["layout"])
                ]),
                _: 1
              }),
              _createVNode(_component_LayoutCol, { class: "spacer" }),
              _createVNode(_component_LayoutCol, { class: "working-colors" }, {
                default: _withCtx(() => [
                  _createVNode(_component_WidgetLayout, { layout: _ctx.workingColorsLayout }, null, 8, ["layout"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutCol, { class: "viewport" }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutRow, { class: "bar-area" }, {
                default: _withCtx(() => [
                  _createVNode(_component_CanvasRuler, {
                    origin: _ctx.rulerOrigin.x,
                    majorMarkSpacing: _ctx.rulerSpacing,
                    numberInterval: _ctx.rulerInterval,
                    direction: 'Horizontal',
                    class: "top-ruler",
                    ref: "rulerHorizontal"
                  }, null, 8, ["origin", "majorMarkSpacing", "numberInterval"])
                ]),
                _: 1
              }),
              _createVNode(_component_LayoutRow, { class: "canvas-area" }, {
                default: _withCtx(() => [
                  _createVNode(_component_LayoutCol, { class: "bar-area" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CanvasRuler, {
                        origin: _ctx.rulerOrigin.y,
                        majorMarkSpacing: _ctx.rulerSpacing,
                        numberInterval: _ctx.rulerInterval,
                        direction: 'Vertical',
                        ref: "rulerVertical"
                      }, null, 8, ["origin", "majorMarkSpacing", "numberInterval"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_LayoutCol, { class: "canvas-area" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "canvas",
                        style: _normalizeStyle({ cursor: _ctx.canvasCursor }),
                        onPointerdown: _cache[0] || (_cache[0] = (e) => _ctx.canvasPointerDown(e)),
                        onDragover: _cache[1] || (_cache[1] = (e) => e.preventDefault()),
                        onDrop: _cache[2] || (_cache[2] = (e) => _ctx.pasteFile(e)),
                        ref: "canvas",
                        "data-canvas": ""
                      }, [
                        (_openBlock(), _createElementBlock("svg", {
                          class: "artboards",
                          innerHTML: _ctx.artboardSvg,
                          style: _normalizeStyle({ width: _ctx.canvasSvgWidth, height: _ctx.canvasSvgHeight })
                        }, null, 12, _hoisted_1)),
                        (_openBlock(), _createElementBlock("svg", {
                          class: "artwork",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          innerHTML: _ctx.artworkSvg,
                          style: _normalizeStyle({ width: _ctx.canvasSvgWidth, height: _ctx.canvasSvgHeight })
                        }, null, 12, _hoisted_2)),
                        (_openBlock(), _createElementBlock("svg", {
                          class: "overlays",
                          innerHTML: _ctx.overlaysSvg,
                          style: _normalizeStyle({ width: _ctx.canvasSvgWidth, height: _ctx.canvasSvgHeight })
                        }, null, 12, _hoisted_3))
                      ], 36)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_LayoutCol, { class: "bar-area" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PersistentScrollbar, {
                        direction: 'Vertical',
                        handlePosition: _ctx.scrollbarPos.y,
                        "onUpdate:handlePosition": _cache[3] || (_cache[3] = (newValue) => _ctx.translateCanvasY(newValue)),
                        handleLength: _ctx.scrollbarSize.y,
                        "onUpdate:handleLength": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.scrollbarSize.y) = $event)),
                        onPressTrack: _cache[5] || (_cache[5] = (delta) => _ctx.pageY(delta)),
                        class: "right-scrollbar"
                      }, null, 8, ["handlePosition", "handleLength"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_LayoutRow, { class: "bar-area" }, {
                default: _withCtx(() => [
                  _createVNode(_component_PersistentScrollbar, {
                    direction: 'Horizontal',
                    handlePosition: _ctx.scrollbarPos.x,
                    "onUpdate:handlePosition": _cache[6] || (_cache[6] = (newValue) => _ctx.translateCanvasX(newValue)),
                    handleLength: _ctx.scrollbarSize.x,
                    "onUpdate:handleLength": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.scrollbarSize.x) = $event)),
                    onPressTrack: _cache[8] || (_cache[8] = (delta) => _ctx.pageX(delta)),
                    class: "bottom-scrollbar"
                  }, null, 8, ["handlePosition", "handleLength"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}