import { vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "spellcheck", "disabled"]
const _hoisted_2 = ["id", "spellcheck", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, {
    class: _normalizeClass(["field-input", { disabled: _ctx.disabled }])
  }, {
    default: _withCtx(() => [
      (!_ctx.textarea)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            class: _normalizeClass({ 'has-label': _ctx.label }),
            id: `field-input-${_ctx.id}`,
            ref: "input",
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            spellcheck: _ctx.spellcheck,
            disabled: _ctx.disabled,
            onFocus: _cache[1] || (_cache[1] = () => _ctx.$emit('textFocused')),
            onBlur: _cache[2] || (_cache[2] = () => _ctx.$emit('textChanged')),
            onChange: _cache[3] || (_cache[3] = () => _ctx.$emit('textChanged')),
            onKeydown: [
              _cache[4] || (_cache[4] = _withKeys(() => _ctx.$emit('textChanged'), ["enter"])),
              _cache[5] || (_cache[5] = _withKeys(() => _ctx.$emit('cancelTextChange'), ["esc"]))
            ]
          }, null, 42, _hoisted_1)), [
            [_vModelText, _ctx.inputValue]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 1,
            class: _normalizeClass([{ 'has-label': _ctx.label }, "scrollable-y"]),
            id: `field-input-${_ctx.id}`,
            "data-scrollable-y": "",
            ref: "input",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inputValue) = $event)),
            spellcheck: _ctx.spellcheck,
            disabled: _ctx.disabled,
            onFocus: _cache[7] || (_cache[7] = () => _ctx.$emit('textFocused')),
            onBlur: _cache[8] || (_cache[8] = () => _ctx.$emit('textChanged')),
            onChange: _cache[9] || (_cache[9] = () => _ctx.$emit('textChanged')),
            onKeydown: [
              _cache[10] || (_cache[10] = _withKeys(_withModifiers(() => !_ctx.macKeyboardLayout && _ctx.$emit('textChanged'), ["ctrl"]), ["enter"])),
              _cache[11] || (_cache[11] = _withKeys(_withModifiers(() => _ctx.macKeyboardLayout && _ctx.$emit('textChanged'), ["meta"]), ["enter"])),
              _cache[12] || (_cache[12] = _withKeys(() => _ctx.$emit('cancelTextChange'), ["esc"]))
            ]
          }, null, 42, _hoisted_2)), [
            [_vModelText, _ctx.inputValue]
          ]),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 2,
            for: `field-input-${_ctx.id}`
          }, _toDisplayString(_ctx.label), 9, _hoisted_3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}