
import { defineComponent, type PropType } from "vue";

import LayoutRow from "@/components/layout/LayoutRow.vue";

export default defineComponent({
	props: {
		maximized: { type: Boolean as PropType<boolean>, default: false },
	},
	components: { LayoutRow },
});
