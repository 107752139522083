
import { defineComponent, type PropType } from "vue";

import { type IconName, type IconSize } from "@/utility-functions/icons";

import IconLabel from "@/components/widgets/labels/IconLabel.vue";

export default defineComponent({
	props: {
		icon: { type: String as PropType<IconName>, required: true },
		size: { type: Number as PropType<IconSize>, required: true },
		active: { type: Boolean as PropType<boolean>, default: false },
		tooltip: { type: String as PropType<string | undefined>, required: false },

		// Callbacks
		action: { type: Function as PropType<(e?: MouseEvent) => void>, required: true },
	},
	components: { IconLabel },
});
