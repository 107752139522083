
import { defineComponent, type PropType } from "vue";

import { platformIsMac } from "@/utility-functions/platform";

import { type KeysGroup, type Key } from "@/wasm-communication/messages";

import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import Document from "@/components/panels/Document.vue";
import LayerTree from "@/components/panels/LayerTree.vue";
import NodeGraph from "@/components/panels/NodeGraph.vue";
import Properties from "@/components/panels/Properties.vue";
import IconButton from "@/components/widgets/buttons/IconButton.vue";
import PopoverButton from "@/components/widgets/buttons/PopoverButton.vue";
import TextButton from "@/components/widgets/buttons/TextButton.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";
import UserInputLabel from "@/components/widgets/labels/UserInputLabel.vue";

const PANEL_COMPONENTS = {
	Document,
	IconButton,
	LayerTree,
	NodeGraph,
	PopoverButton,
	Properties,
	TextButton,
};
type PanelTypes = keyof typeof PANEL_COMPONENTS;

export default defineComponent({
	inject: ["editor"],
	props: {
		tabMinWidths: { type: Boolean as PropType<boolean>, default: false },
		tabCloseButtons: { type: Boolean as PropType<boolean>, default: false },
		tabLabels: { type: Array as PropType<string[]>, required: true },
		tabActiveIndex: { type: Number as PropType<number>, required: true },
		panelType: { type: String as PropType<PanelTypes>, required: false },
		clickAction: { type: Function as PropType<(index: number) => void>, required: false },
		closeAction: { type: Function as PropType<(index: number) => void>, required: false },
	},
	methods: {
		newDocument() {
			this.editor.instance.newDocumentDialog();
		},
		openDocument() {
			this.editor.instance.documentOpen();
		},
		platformModifiers(reservedKey: boolean): KeysGroup {
			// TODO: Remove this by properly feeding these keys from a layout provided by the backend

			const ALT: Key = { key: "Alt", label: "Alt" };
			const COMMAND: Key = { key: "Command", label: "Command" };
			const CONTROL: Key = { key: "Control", label: "Ctrl" };

			if (platformIsMac()) return reservedKey ? [ALT, COMMAND] : [COMMAND];
			return reservedKey ? [CONTROL, ALT] : [CONTROL];
		},
	},
	components: {
		IconLabel,
		LayoutCol,
		LayoutRow,
		TextLabel,
		UserInputLabel,
		...PANEL_COMPONENTS,
	},
});
