
import { defineComponent, type PropType } from "vue";

import { type IconName, type IconStyle, ICONS, ICON_COMPONENTS } from "@/utility-functions/icons";

import LayoutRow from "@/components/layout/LayoutRow.vue";

export default defineComponent({
	props: {
		icon: { type: String as PropType<IconName>, required: true },
		iconStyle: { type: String as PropType<IconStyle | undefined>, required: false },
	},
	computed: {
		iconSizeClass(): string {
			return `size-${ICONS[this.icon].size}`;
		},
		iconStyleClass(): string {
			if (!this.iconStyle || this.iconStyle === "Normal") return "";
			return `${this.iconStyle.toLowerCase()}-style`;
		},
	},
	components: {
		LayoutRow,
		...ICON_COMPONENTS,
	},
});
