
import { defineComponent, type PropType } from "vue";

import { rgbaToDecimalRgba } from "@/utility-functions/color";
import { type RGBA, type Color } from "@/wasm-communication/messages";

import ColorPicker from "@/components/floating-menus/ColorPicker.vue";
import FloatingMenu from "@/components/floating-menus/FloatingMenu.vue";
import LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";

export default defineComponent({
	inject: ["editor"],
	props: {
		primary: { type: Object as PropType<Color>, required: true },
		secondary: { type: Object as PropType<Color>, required: true },
	},
	data() {
		return {
			primaryOpen: false,
			secondaryOpen: false,
		};
	},
	methods: {
		clickPrimarySwatch() {
			this.primaryOpen = true;
			this.secondaryOpen = false;
		},
		clickSecondarySwatch() {
			this.primaryOpen = false;
			this.secondaryOpen = true;
		},
		primaryColorChanged(color: RGBA) {
			const newColor = rgbaToDecimalRgba(color);
			this.editor.instance.updatePrimaryColor(newColor.r, newColor.g, newColor.b, newColor.a);
		},
		secondaryColorChanged(color: RGBA) {
			const newColor = rgbaToDecimalRgba(color);
			this.editor.instance.updateSecondaryColor(newColor.r, newColor.g, newColor.b, newColor.a);
		},
	},
	components: {
		ColorPicker,
		FloatingMenu,
		LayoutCol,
		LayoutRow,
	},
});
