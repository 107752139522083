import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "optional-input" }, {
    default: _withCtx(() => [
      _createVNode(_component_CheckboxInput, {
        checked: _ctx.checked,
        onInput: _cache[0] || (_cache[0] = (e) => _ctx.$emit('update:checked', (e.target as HTMLInputElement).checked)),
        icon: _ctx.icon,
        tooltip: _ctx.tooltip
      }, null, 8, ["checked", "icon", "tooltip"])
    ]),
    _: 1
  }))
}