import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_ColorInput = _resolveComponent("ColorInput")!
  const _component_DropdownInput = _resolveComponent("DropdownInput")!
  const _component_FontInput = _resolveComponent("FontInput")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_OptionalInput = _resolveComponent("OptionalInput")!
  const _component_PivotAssist = _resolveComponent("PivotAssist")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_RadioInput = _resolveComponent("RadioInput")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_SwatchPairInput = _resolveComponent("SwatchPairInput")!
  const _component_TextAreaInput = _resolveComponent("TextAreaInput")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_TextLabel = _resolveComponent("TextLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`widget-${_ctx.direction}`)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgets, (component, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (component.props.kind === 'CheckboxInput')
          ? (_openBlock(), _createBlock(_component_CheckboxInput, _mergeProps({ key: 0 }, component.props, {
              "onUpdate:checked": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onUpdate:checked"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'ColorInput')
          ? (_openBlock(), _createBlock(_component_ColorInput, _mergeProps({ key: 1 }, component.props, {
              open: _ctx.open,
              "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.open) = $event)),
              "onUpdate:value": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["open", "onUpdate:value"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'DropdownInput')
          ? (_openBlock(), _createBlock(_component_DropdownInput, _mergeProps({ key: 2 }, component.props, {
              open: _ctx.open,
              "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.open) = $event)),
              "onUpdate:selectedIndex": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["open", "onUpdate:selectedIndex"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'FontInput')
          ? (_openBlock(), _createBlock(_component_FontInput, _mergeProps({ key: 3 }, component.props, {
              open: _ctx.open,
              "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.open) = $event)),
              onChangeFont: (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["open", "onChangeFont"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'IconButton')
          ? (_openBlock(), _createBlock(_component_IconButton, _mergeProps({ key: 4 }, component.props, {
              action: () => _ctx.updateLayout(component.widgetId, undefined)
            }), null, 16, ["action"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'IconLabel')
          ? (_openBlock(), _createBlock(_component_IconLabel, _normalizeProps(_mergeProps({ key: 5 }, component.props)), null, 16))
          : _createCommentVNode("", true),
        (component.props.kind === 'NumberInput')
          ? (_openBlock(), _createBlock(_component_NumberInput, _mergeProps({ key: 6 }, component.props, {
              "onUpdate:value": (value) => _ctx.updateLayout(component.widgetId, value),
              incrementCallbackIncrease: () => _ctx.updateLayout(component.widgetId, 'Increment'),
              incrementCallbackDecrease: () => _ctx.updateLayout(component.widgetId, 'Decrement')
            }), null, 16, ["onUpdate:value", "incrementCallbackIncrease", "incrementCallbackDecrease"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'OptionalInput')
          ? (_openBlock(), _createBlock(_component_OptionalInput, _mergeProps({ key: 7 }, component.props, {
              "onUpdate:checked": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onUpdate:checked"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'PivotAssist')
          ? (_openBlock(), _createBlock(_component_PivotAssist, _mergeProps({ key: 8 }, component.props, {
              "onUpdate:position": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onUpdate:position"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'PopoverButton')
          ? (_openBlock(), _createBlock(_component_PopoverButton, _normalizeProps(_mergeProps({ key: 9 }, component.props)), {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString((component.props as any).header), 1),
                _createElementVNode("p", null, _toDisplayString((component.props as any).text), 1)
              ]),
              _: 2
            }, 1040))
          : _createCommentVNode("", true),
        (component.props.kind === 'RadioInput')
          ? (_openBlock(), _createBlock(_component_RadioInput, _mergeProps({ key: 10 }, component.props, {
              "onUpdate:selectedIndex": (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onUpdate:selectedIndex"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'Separator')
          ? (_openBlock(), _createBlock(_component_Separator, _normalizeProps(_mergeProps({ key: 11 }, component.props)), null, 16))
          : _createCommentVNode("", true),
        (component.props.kind === 'SwatchPairInput')
          ? (_openBlock(), _createBlock(_component_SwatchPairInput, _normalizeProps(_mergeProps({ key: 12 }, component.props)), null, 16))
          : _createCommentVNode("", true),
        (component.props.kind === 'TextAreaInput')
          ? (_openBlock(), _createBlock(_component_TextAreaInput, _mergeProps({ key: 13 }, component.props, {
              onCommitText: (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onCommitText"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'TextButton')
          ? (_openBlock(), _createBlock(_component_TextButton, _mergeProps({ key: 14 }, component.props, {
              action: () => _ctx.updateLayout(component.widgetId, undefined)
            }), null, 16, ["action"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'TextInput')
          ? (_openBlock(), _createBlock(_component_TextInput, _mergeProps({ key: 15 }, component.props, {
              onCommitText: (value) => _ctx.updateLayout(component.widgetId, value)
            }), null, 16, ["onCommitText"]))
          : _createCommentVNode("", true),
        (component.props.kind === 'TextLabel')
          ? (_openBlock(), _createBlock(_component_TextLabel, _normalizeProps(_mergeProps({ key: 16 }, _ctx.withoutValue(component.props))), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString((component.props as any).value), 1)
              ]),
              _: 2
            }, 1040))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ], 2))
}