import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["d"]
const _hoisted_2 = ["transform"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["canvas-ruler", _ctx.direction.toLowerCase()]),
    ref: "rulerRef"
  }, [
    (_openBlock(), _createElementBlock("svg", {
      style: _normalizeStyle(_ctx.svgBounds)
    }, [
      _createElementVNode("path", { d: _ctx.svgPath }, null, 8, _hoisted_1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.svgTexts, (svgText, index) => {
        return (_openBlock(), _createElementBlock("text", {
          key: index,
          transform: svgText.transform
        }, _toDisplayString(svgText.text), 9, _hoisted_2))
      }), 128))
    ], 4))
  ], 2))
}