
import { defineComponent, type PropType } from "vue";

import { type IconName } from "@/utility-functions/icons";

import LayoutRow from "@/components/layout/LayoutRow.vue";
import CheckboxInput from "@/components/widgets/inputs/CheckboxInput.vue";

export default defineComponent({
	emits: ["update:checked"],
	props: {
		checked: { type: Boolean as PropType<boolean>, required: true },
		icon: { type: String as PropType<IconName>, default: "Checkmark" },
		tooltip: { type: String as PropType<string | undefined>, required: false },
	},
	components: {
		CheckboxInput,
		LayoutRow,
	},
});
