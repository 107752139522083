
import { defineComponent, type PropType } from "vue";

import { type SeparatorDirection, type SeparatorType } from "@/wasm-communication/messages";

export default defineComponent({
	props: {
		direction: { type: String as PropType<SeparatorDirection>, default: "Horizontal" },
		type: { type: String as PropType<SeparatorType>, default: "Unrelated" },
	},
});
