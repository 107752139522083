import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['icon-button', `size-${_ctx.size}`, _ctx.active && 'active']),
    onClick: _cache[0] || (_cache[0] = (e) => _ctx.action(e)),
    title: _ctx.tooltip
  }, [
    _createVNode(_component_IconLabel, { icon: _ctx.icon }, null, 8, ["icon"])
  ], 10, _hoisted_1))
}