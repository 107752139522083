import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "group-gap"
}
const _hoisted_2 = {
  key: 0,
  class: "input-mouse"
}
const _hoisted_3 = {
  key: 1,
  class: "hint-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_ctx.displayKeyboardLockNotice)
    ? (_openBlock(), _createBlock(_component_IconLabel, {
        key: 0,
        class: "user-input-label keyboard-lock-notice",
        icon: 'Info',
        title: _ctx.keyboardLockInfoMessage
      }, null, 8, ["title"]))
    : (_openBlock(), _createBlock(_component_LayoutRow, {
        key: 1,
        class: "user-input-label"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keysWithLabelsGroups, (keysWithLabels, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (i > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyTextOrIconList(keysWithLabels), (keyInfo, j) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: j,
                  class: _normalizeClass(["input-key", keyInfo.width])
                }, [
                  (keyInfo.icon)
                    ? (_openBlock(), _createBlock(_component_IconLabel, {
                        key: 0,
                        icon: keyInfo.icon
                      }, null, 8, ["icon"]))
                    : (keyInfo.label !== undefined)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(keyInfo.label), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ], 64))
          }), 128)),
          (_ctx.mouseMotion)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createVNode(_component_IconLabel, {
                  icon: _ctx.mouseHintIcon(_ctx.mouseMotion)
                }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.hasSlotContent)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }))
}