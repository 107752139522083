import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LayoutRow, {
      class: "window-button windows minimize",
      title: "Minimize"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IconLabel, { icon: 'WindowButtonWinMinimize' })
      ]),
      _: 1
    }),
    (!_ctx.maximized)
      ? (_openBlock(), _createBlock(_component_LayoutRow, {
          key: 0,
          class: "window-button windows maximize",
          title: "Maximize"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconLabel, { icon: 'WindowButtonWinMaximize' })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.maximized)
      ? (_openBlock(), _createBlock(_component_LayoutRow, {
          key: 1,
          class: "window-button windows restore-down",
          title: "Restore Down"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconLabel, { icon: 'WindowButtonWinRestoreDown' })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_LayoutRow, {
      class: "window-button windows close",
      title: "Close"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IconLabel, { icon: 'WindowButtonWinClose' })
      ]),
      _: 1
    })
  ], 64))
}