import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "checked"]
const _hoisted_2 = ["for", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "checkbox-input" }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        type: "checkbox",
        id: `checkbox-input-${_ctx.id}`,
        checked: _ctx.checked,
        onChange: _cache[0] || (_cache[0] = (e) => _ctx.$emit('update:checked', (e.target as HTMLInputElement).checked))
      }, null, 40, _hoisted_1),
      _createElementVNode("label", {
        for: `checkbox-input-${_ctx.id}`,
        tabindex: "0",
        onKeydown: _cache[1] || (_cache[1] = _withKeys((e) => ((e.target as HTMLElement).previousSibling as HTMLInputElement).click(), ["enter"])),
        title: _ctx.tooltip
      }, [
        _createVNode(_component_LayoutRow, { class: "checkbox-box" }, {
          default: _withCtx(() => [
            _createVNode(_component_IconLabel, { icon: _ctx.icon }, null, 8, ["icon"])
          ]),
          _: 1
        })
      ], 40, _hoisted_2)
    ]),
    _: 1
  }))
}