import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "saturationCursor",
  class: "selection-circle"
}
const _hoisted_2 = {
  ref: "hueCursor",
  class: "selection-pincers"
}
const _hoisted_3 = {
  ref: "opacityCursor",
  class: "selection-pincers"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutCol = _resolveComponent("LayoutCol")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "color-picker" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutCol, {
        class: "saturation-picker",
        ref: "saturationPicker",
        onPointerdown: _cache[0] || (_cache[0] = (e) => _ctx.onPointerDown(e))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, null, 512)
        ]),
        _: 1
      }, 512),
      _createVNode(_component_LayoutCol, {
        class: "hue-picker",
        ref: "huePicker",
        onPointerdown: _cache[1] || (_cache[1] = (e) => _ctx.onPointerDown(e))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, null, 512)
        ]),
        _: 1
      }, 512),
      _createVNode(_component_LayoutCol, {
        class: "opacity-picker",
        ref: "opacityPicker",
        onPointerdown: _cache[2] || (_cache[2] = (e) => _ctx.onPointerDown(e))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, null, 512)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}