import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_MenuList = _resolveComponent("MenuList")!

  return (_openBlock(), _createBlock(_component_LayoutRow, {
    class: "dropdown-input",
    "data-dropdown-input": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, {
        class: _normalizeClass(["dropdown-box", { disabled: _ctx.disabled, open: _ctx.open }]),
        style: _normalizeStyle({ minWidth: `${_ctx.minWidth}px` }),
        onClick: _cache[0] || (_cache[0] = () => !_ctx.disabled && (_ctx.open = true)),
        onBlur: _cache[1] || (_cache[1] = (e) => _ctx.blur(e)),
        onKeydown: _cache[2] || (_cache[2] = (e) => _ctx.keydown(e)),
        ref: "dropdownBox",
        tabindex: "0",
        "data-hover-menu-spawner": ""
      }, {
        default: _withCtx(() => [
          (_ctx.activeEntry.icon)
            ? (_openBlock(), _createBlock(_component_IconLabel, {
                key: 0,
                class: "dropdown-icon",
                icon: _ctx.activeEntry.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.activeEntry.label), 1),
          _createVNode(_component_IconLabel, {
            class: "dropdown-arrow",
            icon: 'DropdownArrow'
          })
        ]),
        _: 1
      }, 8, ["class", "style"]),
      _createVNode(_component_MenuList, {
        activeEntry: _ctx.activeEntry,
        "onUpdate:activeEntry": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeEntry) = $event)),
        open: _ctx.open,
        "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.open) = $event)),
        onNaturalWidth: _cache[5] || (_cache[5] = (newNaturalWidth) => (_ctx.minWidth = newNaturalWidth)),
        entries: _ctx.entries,
        drawIcon: _ctx.drawIcon,
        interactive: _ctx.interactive,
        direction: 'Bottom',
        scrollableY: true,
        ref: "menuList"
      }, null, 8, ["activeEntry", "open", "entries", "drawIcon", "interactive"])
    ]),
    _: 1
  }))
}