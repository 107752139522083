import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-emphasized", "data-disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_TextLabel = _resolveComponent("TextLabel")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["text-button", { emphasized: _ctx.emphasized, disabled: _ctx.disabled }]),
    "data-emphasized": _ctx.emphasized || undefined,
    "data-disabled": _ctx.disabled || undefined,
    "data-text-button": "",
    style: _normalizeStyle(_ctx.minWidth > 0 ? `min-width: ${_ctx.minWidth}px` : ''),
    onClick: _cache[0] || (_cache[0] = (e) => _ctx.action(e))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_IconLabel, {
          key: 0,
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TextLabel, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    })
  ], 14, _hoisted_1))
}