
import { defineComponent, type PropType } from "vue";

import { isWidgetColumn, isWidgetRow, isWidgetSection, type LayoutGroup, type WidgetLayout } from "@/wasm-communication/messages";

import WidgetSection from "@/components/widgets/groups/WidgetSection.vue";
import WidgetRow from "@/components/widgets/WidgetRow.vue";

export default defineComponent({
	props: {
		layout: { type: Object as PropType<WidgetLayout>, required: true },
	},
	methods: {
		LayoutGroupType(layoutRow: LayoutGroup): unknown {
			if (isWidgetColumn(layoutRow)) return WidgetRow;
			if (isWidgetRow(layoutRow)) return WidgetRow;
			if (isWidgetSection(layoutRow)) return WidgetSection;

			throw new Error("Layout row type does not exist");
		},
	},
	data: () => ({
		isWidgetRow,
		isWidgetSection,
	}),
	components: {
		WidgetRow,
		WidgetSection,
	},
});
