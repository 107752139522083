import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_TextLabel = _resolveComponent("TextLabel")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "radio-input" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (entry, index) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass({ active: index === _ctx.selectedIndex }),
          key: index,
          onClick: () => _ctx.handleEntryClick(entry),
          title: entry.tooltip
        }, [
          (entry.icon)
            ? (_openBlock(), _createBlock(_component_IconLabel, {
                key: 0,
                icon: entry.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (entry.label)
            ? (_openBlock(), _createBlock(_component_TextLabel, { key: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(entry.label), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }))
}