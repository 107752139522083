import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldInput = _resolveComponent("FieldInput")!

  return (_openBlock(), _createBlock(_component_FieldInput, {
    textarea: true,
    class: _normalizeClass(["text-area-input", { 'has-label': _ctx.label }]),
    value: _ctx.inputValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    label: _ctx.label,
    spellcheck: true,
    disabled: _ctx.disabled,
    onTextFocused: _cache[1] || (_cache[1] = () => _ctx.onTextFocused()),
    onTextChanged: _cache[2] || (_cache[2] = () => _ctx.onTextChanged()),
    onCancelTextChange: _cache[3] || (_cache[3] = () => _ctx.onCancelTextChange()),
    ref: "fieldInput"
  }, null, 8, ["class", "value", "label", "disabled"]))
}