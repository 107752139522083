import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionalInput = _resolveComponent("OptionalInput")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!

  return (_openBlock(), _createBlock(_component_LayoutRow, {
    class: "color-input",
    title: _ctx.tooltip
  }, {
    default: _withCtx(() => [
      (!_ctx.noTransparency)
        ? (_openBlock(), _createBlock(_component_OptionalInput, {
            key: 0,
            icon: 'CloseX',
            checked: Boolean(_ctx.value),
            "onUpdate:checked": _cache[0] || (_cache[0] = (state) => _ctx.updateEnabled(state))
          }, null, 8, ["checked"]))
        : _createCommentVNode("", true),
      _createVNode(_component_TextInput, {
        value: _ctx.displayValue,
        label: _ctx.label,
        disabled: _ctx.disabled || !_ctx.value,
        onCommitText: _cache[1] || (_cache[1] = (value) => _ctx.textInputUpdated(value)),
        center: true
      }, null, 8, ["value", "label", "disabled"]),
      _createVNode(_component_Separator, { type: 'Related' }),
      _createVNode(_component_LayoutRow, { class: "swatch" }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            class: _normalizeClass(["swatch-button", { 'disabled-swatch': !_ctx.value }]),
            style: _normalizeStyle(`--swatch-color: #${_ctx.value}`),
            onClick: _cache[2] || (_cache[2] = () => _ctx.$emit('update:open', true))
          }, null, 6),
          _createVNode(_component_FloatingMenu, {
            open: _ctx.isOpen,
            "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOpen) = $event)),
            type: 'Popover',
            direction: 'Bottom'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ColorPicker, {
                "onUpdate:color": _cache[3] || (_cache[3] = (color) => _ctx.colorPickerUpdated(color)),
                color: _ctx.color
              }, null, 8, ["color"])
            ]),
            _: 1
          }, 8, ["open"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}