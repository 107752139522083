
import { defineComponent, type PropType } from "vue";

export default defineComponent({
	props: {
		bold: { type: Boolean as PropType<boolean>, default: false },
		italic: { type: Boolean as PropType<boolean>, default: false },
		tableAlign: { type: Boolean as PropType<boolean>, default: false },
		multiline: { type: Boolean as PropType<boolean>, default: false },
	},
});
