import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WindowButtonsMac = _resolveComponent("WindowButtonsMac")!
  const _component_MenuBarInput = _resolveComponent("MenuBarInput")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_WindowTitle = _resolveComponent("WindowTitle")!
  const _component_WindowButtonsWindows = _resolveComponent("WindowButtonsWindows")!
  const _component_WindowButtonsWeb = _resolveComponent("WindowButtonsWeb")!

  return (_openBlock(), _createBlock(_component_LayoutRow, { class: "title-bar" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, { class: "header-part" }, {
        default: _withCtx(() => [
          (_ctx.platform === 'Mac')
            ? (_openBlock(), _createBlock(_component_WindowButtonsMac, {
                key: 0,
                maximized: _ctx.maximized
              }, null, 8, ["maximized"]))
            : _createCommentVNode("", true),
          (_ctx.platform !== 'Mac')
            ? (_openBlock(), _createBlock(_component_MenuBarInput, { key: 1 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, { class: "header-part" }, {
        default: _withCtx(() => [
          _createVNode(_component_WindowTitle, { text: _ctx.windowTitle }, null, 8, ["text"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, { class: "header-part" }, {
        default: _withCtx(() => [
          (_ctx.platform === 'Windows' || _ctx.platform === 'Linux')
            ? (_openBlock(), _createBlock(_component_WindowButtonsWindows, {
                key: 0,
                maximized: _ctx.maximized
              }, null, 8, ["maximized"]))
            : _createCommentVNode("", true),
          (_ctx.platform === 'Web')
            ? (_openBlock(), _createBlock(_component_WindowButtonsWeb, {
                key: 1,
                maximized: _ctx.maximized
              }, null, 8, ["maximized"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}