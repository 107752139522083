
import { defineComponent } from "vue";

import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";

export default defineComponent({
	inject: ["fullscreen"],
	methods: {
		async handleClick() {
			if (this.fullscreen.state.windowFullscreen) this.fullscreen.exitFullscreen();
			else this.fullscreen.enterFullscreen();
		},
	},
	computed: {
		requestFullscreenHotkeys() {
			return this.fullscreen.keyboardLockApiSupported && !this.fullscreen.state.keyboardLocked;
		},
	},
	components: {
		IconLabel,
		LayoutRow,
		TextLabel,
	},
});
