import type WasmBindgenPackage from "@/../wasm/pkg";
import { panicProxy } from "@/utility-functions/panic-proxy";
import { type JsMessageType } from "@/wasm-communication/messages";
import { createSubscriptionRouter, type SubscriptionRouter } from "@/wasm-communication/subscription-router";

export type WasmRawInstance = typeof WasmBindgenPackage;
export type WasmEditorInstance = InstanceType<WasmRawInstance["JsEditorHandle"]>;
export type Editor = Readonly<ReturnType<typeof createEditor>>;

// `wasmImport` starts uninitialized because its initialization needs to occur asynchronously, and thus needs to occur by manually calling and awaiting `initWasm()`
let wasmImport: WasmRawInstance | undefined;

// Should be called asynchronously before `createEditor()`
export async function initWasm(): Promise<void> {
	// Skip if the WASM module is already initialized
	if (wasmImport !== undefined) return;

	// Import the WASM module JS bindings and wrap them in the panic proxy
	wasmImport = await import("@/../wasm/pkg").then(panicProxy);

	// Provide a random starter seed which must occur after initializing the WASM module, since WASM can't generate its own random numbers
	const randomSeed = BigInt(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
	wasmImport?.setRandomSeed(randomSeed);
}

// Should be called after running `initWasm()` and its promise resolving
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createEditor() {
	// Raw: Object containing several callable functions from `editor_api.rs` defined directly on the WASM module, not the editor instance (generated by wasm-bindgen)
	if (!wasmImport) throw new Error("Editor WASM backend was not initialized at application startup");
	const raw: WasmRawInstance = wasmImport;

	// Instance: Object containing many functions from `editor_api.rs` that are part of the editor instance (generated by wasm-bindgen)
	const instance: WasmEditorInstance = new raw.JsEditorHandle((messageType: JsMessageType, messageData: Record<string, unknown>): void => {
		// This callback is called by WASM when a FrontendMessage is received from the WASM wrapper editor instance
		// We pass along the first two arguments then add our own `raw` and `instance` context for the last two arguments
		subscriptions.handleJsMessage(messageType, messageData, raw, instance);
	});

	// Subscriptions: Allows subscribing to messages in JS that are sent from the WASM backend
	const subscriptions: SubscriptionRouter = createSubscriptionRouter();

	return {
		raw,
		instance,
		subscriptions,
	};
}
