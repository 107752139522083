
import { defineComponent, type PropType } from "vue";

import { type IconName } from "@/utility-functions/icons";

import FloatingMenu from "@/components/floating-menus/FloatingMenu.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconButton from "@/components/widgets/buttons/IconButton.vue";

export default defineComponent({
	props: {
		icon: { type: String as PropType<IconName>, default: "DropdownArrow" },

		// Callbacks
		action: { type: Function as PropType<() => void>, required: false },
	},
	data() {
		return {
			open: false,
		};
	},
	methods: {
		onClick() {
			this.open = true;

			this.action?.();
		},
	},
	components: {
		FloatingMenu,
		IconButton,
		LayoutRow,
	},
});
