import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!
  const _component_WidgetLayout = _resolveComponent("WidgetLayout")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!

  return (_openBlock(), _createBlock(_component_FloatingMenu, {
    open: true,
    class: "dialog-modal",
    type: 'Dialog',
    direction: 'Center',
    "data-dialog-modal": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, { ref: "main" }, {
        default: _withCtx(() => [
          _createVNode(_component_LayoutCol, { class: "icon-column" }, {
            default: _withCtx(() => [
              _createVNode(_component_IconLabel, {
                icon: _ctx.dialog.state.icon,
                class: _normalizeClass(_ctx.dialog.state.icon.toLowerCase())
              }, null, 8, ["icon", "class"])
            ]),
            _: 1
          }),
          _createVNode(_component_LayoutCol, { class: "main-column" }, {
            default: _withCtx(() => [
              (_ctx.dialog.state.widgets.layout.length > 0)
                ? (_openBlock(), _createBlock(_component_WidgetLayout, {
                    key: 0,
                    layout: _ctx.dialog.state.widgets,
                    class: "details"
                  }, null, 8, ["layout"]))
                : _createCommentVNode("", true),
              ((_ctx.dialog.state.jsCallbackBasedButtons?.length || NaN) > 0)
                ? (_openBlock(), _createBlock(_component_LayoutRow, {
                    key: 1,
                    class: "panic-buttons-row"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialog.state.jsCallbackBasedButtons, (button, index) => {
                        return (_openBlock(), _createBlock(_component_TextButton, _mergeProps({
                          key: index,
                          action: () => button.callback?.()
                        }, button.props), null, 16, ["action"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}