
import { defineComponent, type PropType } from "vue";

import { type IconName } from "@/utility-functions/icons";

import IconLabel from "@/components/widgets/labels/IconLabel.vue";
import TextLabel from "@/components/widgets/labels/TextLabel.vue";

export type TextButtonWidget = {
	tooltip?: string;
	message?: string | object;
	callback?: () => void;
	props: {
		kind: "TextButton";
		label: string;
		icon?: string;
		emphasized?: boolean;
		minWidth?: number;
		disabled?: boolean;

		// Callbacks
		// `action` is used via `IconButtonWidget.callback`
	};
};

export default defineComponent({
	props: {
		label: { type: String as PropType<string>, required: true },
		icon: { type: String as PropType<IconName | undefined>, required: false },
		emphasized: { type: Boolean as PropType<boolean>, default: false },
		minWidth: { type: Number as PropType<number>, default: 0 },
		disabled: { type: Boolean as PropType<boolean>, default: false },

		// Callbacks
		action: { type: Function as PropType<(e: MouseEvent) => void>, required: true },
	},
	components: {
		IconLabel,
		TextLabel,
	},
});
