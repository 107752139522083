import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tail",
  ref: "tail"
}
const _hoisted_2 = {
  key: 1,
  class: "floating-menu-container",
  ref: "floatingMenuContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["floating-menu", [_ctx.direction.toLowerCase(), _ctx.type.toLowerCase()]]),
    ref: "floatingMenu"
  }, [
    (_ctx.open && _ctx.type === 'Popover')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
      : _createCommentVNode("", true),
    (_ctx.open || _ctx.measuringOngoing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LayoutCol, {
            class: "floating-menu-content",
            style: _normalizeStyle({ minWidth: _ctx.minWidthStyleValue }),
            scrollableY: _ctx.scrollableY,
            ref: "floatingMenuContent",
            "data-floating-menu-content": ""
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["style", "scrollableY"])
        ], 512))
      : _createCommentVNode("", true)
  ], 2))
}