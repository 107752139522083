import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "swatch-pair" }, {
    default: _withCtx(() => [
      _createVNode(_component_LayoutRow, { class: "secondary swatch" }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = () => _ctx.clickSecondarySwatch()),
            style: _normalizeStyle(`--swatch-color: ${_ctx.secondary.toRgbaCSS()}`),
            "data-hover-menu-spawner": ""
          }, null, 4),
          _createVNode(_component_FloatingMenu, {
            type: 'Popover',
            direction: 'Right',
            open: _ctx.secondaryOpen,
            "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.secondaryOpen) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ColorPicker, {
                "onUpdate:color": _cache[1] || (_cache[1] = (color) => _ctx.secondaryColorChanged(color)),
                color: _ctx.secondary.toRgba()
              }, null, 8, ["color"])
            ]),
            _: 1
          }, 8, ["open"])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutRow, { class: "primary swatch" }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = () => _ctx.clickPrimarySwatch()),
            style: _normalizeStyle(`--swatch-color: ${_ctx.primary.toRgbaCSS()}`),
            "data-hover-menu-spawner": ""
          }, null, 4),
          _createVNode(_component_FloatingMenu, {
            type: 'Popover',
            direction: 'Right',
            open: _ctx.primaryOpen,
            "onUpdate:open": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.primaryOpen) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ColorPicker, {
                "onUpdate:color": _cache[4] || (_cache[4] = (color) => _ctx.primaryColorChanged(color)),
                color: _ctx.primary.toRgba()
              }, null, 8, ["color"])
            ]),
            _: 1
          }, 8, ["open"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}