import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_Workspace = _resolveComponent("Workspace")!
  const _component_StatusBar = _resolveComponent("StatusBar")!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!

  return (_openBlock(), _createBlock(_component_LayoutCol, { class: "main-window" }, {
    default: _withCtx(() => [
      _createVNode(_component_TitleBar, {
        platform: _ctx.platform,
        maximized: _ctx.maximized
      }, null, 8, ["platform", "maximized"]),
      _createVNode(_component_Workspace),
      _createVNode(_component_StatusBar)
    ]),
    _: 1
  }))
}