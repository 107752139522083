import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "no-icon"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 4,
  class: "submenu-arrow"
}
const _hoisted_4 = {
  key: 5,
  class: "no-submenu-arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutRow = _resolveComponent("LayoutRow")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_IconLabel = _resolveComponent("IconLabel")!
  const _component_UserInputLabel = _resolveComponent("UserInputLabel")!
  const _component_MenuList = _resolveComponent("MenuList", true)!
  const _component_LayoutCol = _resolveComponent("LayoutCol")!
  const _component_FloatingMenu = _resolveComponent("FloatingMenu")!

  return (_openBlock(), _createBlock(_component_FloatingMenu, _mergeProps({
    class: "menu-list",
    open: _ctx.isOpen,
    "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    onNaturalWidth: _cache[2] || (_cache[2] = (newNaturalWidth) => _ctx.$emit('naturalWidth', newNaturalWidth)),
    type: 'Dropdown',
    windowEdgeMargin: 0,
    escapeCloses: false
  }, { direction: _ctx.direction, scrollableY: _ctx.scrollableY && _ctx.virtualScrollingEntryHeight === 0, minWidth: _ctx.minWidth }, {
    ref: "floatingMenu",
    "data-hover-menu-keep-open": ""
  }), {
    default: _withCtx(() => [
      _createVNode(_component_LayoutCol, {
        ref: "scroller",
        scrollableY: _ctx.scrollableY && _ctx.virtualScrollingEntryHeight !== 0,
        onScroll: _ctx.onScroll,
        style: _normalizeStyle({ minWidth: _ctx.virtualScrollingEntryHeight ? `${_ctx.minWidth}px` : `inherit` })
      }, {
        default: _withCtx(() => [
          (_ctx.virtualScrollingEntryHeight)
            ? (_openBlock(), _createBlock(_component_LayoutRow, {
                key: 0,
                class: "scroll-spacer",
                style: _normalizeStyle({ height: `${_ctx.virtualScrollingStartIndex * _ctx.virtualScrollingEntryHeight}px` })
              }, null, 8, ["style"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (section, sectionIndex) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: sectionIndex }, [
              (sectionIndex > 0)
                ? (_openBlock(), _createBlock(_component_Separator, {
                    key: 0,
                    type: 'List',
                    direction: 'Vertical'
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.virtualScrollingEntryHeight ? section.slice(_ctx.virtualScrollingStartIndex, _ctx.virtualScrollingEndIndex) : section, (entry, entryIndex) => {
                return (_openBlock(), _createBlock(_component_LayoutRow, {
                  key: entryIndex + (_ctx.virtualScrollingEntryHeight ? _ctx.virtualScrollingStartIndex : 0),
                  class: _normalizeClass(["row", { open: _ctx.isEntryOpen(entry), active: entry.label === _ctx.highlighted?.label, disabled: entry.disabled }]),
                  style: _normalizeStyle({ height: _ctx.virtualScrollingEntryHeight || '20px' }),
                  onClick: () => !entry.disabled && _ctx.onEntryClick(entry),
                  onPointerenter: () => !entry.disabled && _ctx.onEntryPointerEnter(entry),
                  onPointerleave: () => !entry.disabled && _ctx.onEntryPointerLeave(entry)
                }, {
                  default: _withCtx(() => [
                    (entry.icon && _ctx.drawIcon)
                      ? (_openBlock(), _createBlock(_component_IconLabel, {
                          key: 0,
                          icon: entry.icon,
                          class: "entry-icon"
                        }, null, 8, ["icon"]))
                      : (_ctx.drawIcon)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                        : _createCommentVNode("", true),
                    (entry.font)
                      ? (_openBlock(), _createElementBlock("link", {
                          key: 2,
                          rel: "stylesheet",
                          href: entry.font?.toString()
                        }, null, 8, _hoisted_2))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", {
                      class: "entry-label",
                      style: _normalizeStyle({ fontFamily: `${!entry.font ? 'inherit' : entry.value}` })
                    }, _toDisplayString(entry.label), 5),
                    (entry.shortcut?.keys.length)
                      ? (_openBlock(), _createBlock(_component_UserInputLabel, {
                          key: 3,
                          keysWithLabelsGroups: [entry.shortcut.keys],
                          requiresLock: entry.shortcutRequiresLock
                        }, null, 8, ["keysWithLabelsGroups", "requiresLock"]))
                      : _createCommentVNode("", true),
                    (entry.children?.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4)),
                    (entry.children)
                      ? (_openBlock(), _createBlock(_component_MenuList, _mergeProps({
                          key: 6,
                          onNaturalWidth: _cache[0] || (_cache[0] = (newNaturalWidth) => _ctx.$emit('naturalWidth', newNaturalWidth)),
                          open: entry.ref?.open || false,
                          direction: 'TopRight',
                          entries: entry.children
                        }, { minWidth: _ctx.minWidth, drawIcon: _ctx.drawIcon, scrollableY: _ctx.scrollableY }, {
                          ref_for: true,
                          ref: (ref) => ref && (entry.ref = ref)
                        }), null, 16, ["open", "entries"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "style", "onClick", "onPointerenter", "onPointerleave"]))
              }), 128))
            ], 64))
          }), 128)),
          (_ctx.virtualScrollingEntryHeight)
            ? (_openBlock(), _createBlock(_component_LayoutRow, {
                key: 1,
                class: "scroll-spacer",
                style: _normalizeStyle({ height: `${_ctx.virtualScrollingTotalHeight - _ctx.virtualScrollingEndIndex * _ctx.virtualScrollingEntryHeight}px` })
              }, null, 8, ["style"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["scrollableY", "onScroll", "style"])
    ]),
    _: 1
  }, 16, ["open"]))
}