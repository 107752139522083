
import { defineComponent, nextTick, type PropType } from "vue";

import { type MenuListEntry } from "@/wasm-communication/messages";

import type FloatingMenu from "@/components/floating-menus/FloatingMenu.vue";
import MenuList from "@/components/floating-menus/MenuList.vue";
import type LayoutCol from "@/components/layout/LayoutCol.vue";
import LayoutRow from "@/components/layout/LayoutRow.vue";
import IconLabel from "@/components/widgets/labels/IconLabel.vue";

export default defineComponent({
	inject: ["fonts"],
	emits: ["update:fontFamily", "update:fontStyle", "changeFont"],
	props: {
		fontFamily: { type: String as PropType<string>, required: true },
		fontStyle: { type: String as PropType<string>, required: true },
		isStyle: { type: Boolean as PropType<boolean>, default: false },
		disabled: { type: Boolean as PropType<boolean>, default: false },
	},
	data() {
		return {
			open: false,
			entries: [] as MenuListEntry[],
			activeEntry: undefined as MenuListEntry | undefined,
			highlighted: undefined as MenuListEntry | undefined,
			entriesStart: 0,
			minWidth: this.isStyle ? 0 : 300,
		};
	},
	async mounted() {
		this.entries = await this.getEntries();
		this.activeEntry = this.getActiveEntry(this.entries);
		this.highlighted = this.activeEntry;
	},
	methods: {
		floatingMenu() {
			return this.$refs.floatingMenu as typeof FloatingMenu;
		},
		scroller() {
			return ((this.$refs.menulist as typeof MenuList).$refs.scroller as typeof LayoutCol)?.$el as HTMLElement;
		},
		async setOpen() {
			this.open = true;

			// Scroll to the active entry (the scroller div does not yet exist so we must wait for vue to render)
			await nextTick();
			if (this.activeEntry) {
				const index = this.entries.indexOf(this.activeEntry);
				this.scroller()?.scrollTo(0, Math.max(0, index * 20 - 190));
			}
		},
		toggleOpen() {
			if (this.disabled) return;
			this.open = !this.open;
			if (this.open) this.setOpen();
		},
		keydown(e: KeyboardEvent) {
			(this.$refs.menulist as typeof MenuList).keydown(e, false);
		},
		async selectFont(newName: string): Promise<void> {
			let fontFamily;
			let fontStyle;

			if (this.isStyle) {
				this.$emit("update:fontStyle", newName);

				fontFamily = this.fontFamily;
				fontStyle = newName;
			} else {
				this.$emit("update:fontFamily", newName);

				fontFamily = newName;
				fontStyle = "Normal (400)";
			}

			const fontFileUrl = await this.fonts.getFontFileUrl(fontFamily, fontStyle);
			this.$emit("changeFont", { fontFamily, fontStyle, fontFileUrl });
		},
		async getEntries(): Promise<MenuListEntry[]> {
			const x = this.isStyle ? this.fonts.getFontStyles(this.fontFamily) : this.fonts.fontNames();
			return (await x).map((entry: { name: string; url: URL | undefined }) => ({
				label: entry.name,
				value: entry.name,
				font: entry.url,
				action: () => this.selectFont(entry.name),
			}));
		},
		getActiveEntry(entries: MenuListEntry[]): MenuListEntry {
			const selectedChoice = this.isStyle ? this.fontStyle : this.fontFamily;

			return entries.find((entry) => entry.value === selectedChoice) as MenuListEntry;
		},
	},
	watch: {
		async fontFamily() {
			this.entries = await this.getEntries();
			this.activeEntry = this.getActiveEntry(this.entries);
			this.highlighted = this.activeEntry;
		},
		async fontStyle() {
			this.entries = await this.getEntries();
			this.activeEntry = this.getActiveEntry(this.entries);
			this.highlighted = this.activeEntry;
		},
	},
	components: {
		IconLabel,
		LayoutRow,
		MenuList,
	},
});
